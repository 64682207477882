import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { MailTabsLoading } from './MailTabsLoading/MailTabsLoading';

type propsType = {
    data?: any
}

export const MailTabs = ({ data }: propsType) => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        axios.get(
            (process.env.REACT_APP_VOX_TABS_DETAIL as string) + `/${data}/mails`,
            {
                headers: {
                    "Authorization": `Bearer ${globalStateUser.token}`
                }
            }
        )
            .then(res => {
                setIsLoading(false)
                setDataTable(res.data.data)
            })
            .catch(error => {
                setIsLoading(true);
                console.log(error)
            })
    }, []);

    return (
        <div>
            {
                isLoading ?
                    <MailTabsLoading />
                    :
                    <div className="overflow-x-auto border border-slate-200 rounded-md">
                        <table className="table w-full">
                            {/* head */}
                            <thead>
                                <tr>
                                    <th />
                                    <th>Mail ID</th>
                                    <th>Received At</th>
                                    <th>Item Description</th>
                                    <th>Sender Info</th>
                                    <th>Recipient Name</th>
                                    <th>Taken by Client at</th>
                                    <th>Notified To</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataTable.length === 0 ?
                                        <tr>
                                            <th></th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>No Data</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        :
                                        dataTable.map((data: any, idx: any) => {
                                            return <tr key={idx}>
                                                <th />
                                                <td>{data?.id}</td>
                                                <td>{data?.received_at}</td>
                                                <td>{data?.item_description}</td>
                                                <td>{data?.sender_info}</td>
                                                <td>{data?.recipient_name}</td>
                                                <td>{data?.taken_by_client_at}</td>
                                                <td>{data?.notified_to}</td>
                                            </tr>
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}
