import { faFile } from "@fortawesome/free-regular-svg-icons";
import {
  faFileCircleCheck,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { rupiah } from "../../../../../helper/rupiah";
import { RootState } from "../../../../../store/store";
import { ClientBadgeStatus } from "../../../ClientPlans/ClientBadgeStatus/ClientBadgeStatus";
Modal.setAppElement("#root");

type InvoiceDetailDataType = {
  invoice_no: number;
  status_invoice: string;
  period_from: string;
  period_to: string;
  location: string;
  client_name: string;
  email: string;
  amount_due: number;
  discount_percentage: number;
  discount: number;
  vat: number;
  vat_price: number;
  pph23: number;
  pph42: number;
  items: [];
  old_invoice_id: number;
  due_date: string;
  price: number;
  status: string;
};

type propsType = {
  data?: any;
};

export const ClientCollectionTable = ({ data }: propsType) => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const [isOpenModalCollection, setIsOpenModalCollection] = useState(false);
  const [isOpenModalViewProforma, setIsOpenModalViewProforma] = useState(false);
  const [isOpenModalOptionViewProforma, setIsOpenModalOptionViewProforma] =
    useState(false);
  const [pdfProformaInvoice, setPdfProformaInvoice] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetailDataType>();
  function toggleModalModalCollection(data?: any) {
    setIsOpenModalCollection(!isOpenModalCollection);
    if (!isOpenModalCollection) {
      setIsLoading(true);
      axios
        .get(
          (process.env.REACT_APP_VOX_VO_COLLECTION_INVOICE as string) +
            data?.id,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((res) => {
          setInvoiceDetail({
            ...invoiceDetail,
            invoice_no: data.id,
            status_invoice: res.data.data?.status_invoice,
            period_from: res.data.data?.period_from,
            period_to: res.data.data?.period_to,
            location: res.data.data?.location?.name,
            client_name: res.data.data?.client?.full_name,
            email: res.data.data?.client?.email,
            amount_due: res.data.data?.final_result?.grand_total,
            discount_percentage: res.data.data?.final_result?.discount?.label,
            discount: res.data.data?.final_result?.discount?.nominal,
            vat: res.data.data?.final_result?.vat?.percentage,
            vat_price: res.data.data?.final_result?.vat?.nominal,
            pph23: res.data.data?.final_result?.pph23,
            pph42: res.data.data?.final_result?.pph42,
            items: res.data.data.details,
            old_invoice_id: res.data.data.old_final_result?.id,
            due_date: res.data.data.old_final_result?.date_due,
            price: res.data.data.old_final_result?.final_result?.grand_total,
            status: res.data.data.old_final_result?.status_invoice,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }

  function toggleModalOptionViewProforma(data?: any) {
    setIsOpenModalOptionViewProforma(!isOpenModalOptionViewProforma);
  }

  function toggleModalViewProforma(data?: any) {
    setIsOpenModalViewProforma(!isOpenModalViewProforma);
    if (!isOpenModalViewProforma) {
      axios
        .get(
          (process.env.REACT_APP_VOX_PREVIEW_PROFORMA_INVOICE as string) +
            `${data}/check`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.message === "exist") {
            setPdfProformaInvoice(res.data?.data?.url);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${res.data.message}`,
            });
            setIsOpenModalViewProforma(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  }

  const handleSendInvoice = (invoiceId: any) => {
    setIsLoading(true);
    const dataPost = {
      invoice_id: invoiceId,
    };
    axios
      .post(process.env.REACT_APP_INVOICE_COLLECTION_SEND as string, dataPost, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((res) => {
        Swal.fire("Invoice Send!", "", "success");
        setIsLoading(false);
        // navigate('/vo-data')
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpenModalCollection}
        onRequestClose={toggleModalModalCollection}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-8/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalModalCollection}
          >
            ✕
          </label>
          <h3 className="text-xl font-bold">
            Invoice Number {invoiceDetail?.invoice_no}
          </h3>
          <div className="divider"></div>
          {/* <button
            className="btn btn-sm bg-pink-700 border-0"
            onClick={() => handleSendInvoice(invoiceDetail?.invoice_no)}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
            &nbsp;&nbsp;Send Invoice
          </button> */}
          <div className="flex flex-col md:flex-row items-center">
            {invoiceDetail?.status_invoice === "unpaid" ? (
              <button
                className={
                  isLoading
                    ? "btn btn-sm loading"
                    : "btn btn-sm bg-pink-700 border-0"
                }
                onClick={toggleModalOptionViewProforma}
              >
                {isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}
                &nbsp;&nbsp;Invoice
              </button>
            ) : (
              <button
                className={
                  isLoading
                    ? "btn btn-sm loading"
                    : "btn btn-sm bg-pink-700 border-0"
                }
                // onClick={() =>
                //   toggleModalViewProforma(invoiceDetail?.invoice_no)
                // }
              >
                <FontAwesomeIcon icon={faPaperPlane} />
                &nbsp;&nbsp;Invoice
              </button>
            )}
          </div>
          <div className="divider"></div>
          <div className="flex flex-row justify-evenly flex-wrap">
            <div>
              <div className="mb-4">
                <p className="font-bold text-center">Status</p>
                <p className="font-light text-center">
                  {invoiceDetail?.status_invoice}
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold text-center">Email</p>
                <p className="font-light text-center">{invoiceDetail?.email}</p>
              </div>
              <div className="mb-4">
                <p className="font-bold text-center">VAT Nominal</p>
                <p className="font-light text-center">
                  {rupiah(invoiceDetail?.vat_price)}
                </p>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <p className="font-bold text-center">Period From</p>
                <p className="font-light text-center">
                  {invoiceDetail?.period_from}
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold text-center">Amount Paid</p>
                <p className="font-light text-center">
                  {rupiah(invoiceDetail?.amount_due)}
                </p>
              </div>
              {invoiceDetail?.discount ? (
                <>
                  <div className="mb-4">
                    <p className="font-bold text-center">Discount</p>
                    <p className="font-light text-center">
                      {invoiceDetail?.discount_percentage}
                    </p>
                  </div>
                </>
              ) : null}
            </div>
            <div>
              <div className="mb-4">
                <p className="font-bold text-center">Period To</p>
                <p className="font-light text-center">
                  {invoiceDetail?.period_to}
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold text-center">PPH 23</p>
                <p className="font-light text-center">
                  {rupiah(invoiceDetail?.pph23)}
                </p>
              </div>
              {invoiceDetail?.discount ? (
                <>
                  <div className="mb-4">
                    <p className="font-bold text-center">Discount Price</p>
                    <p className="font-light text-center">
                      {rupiah(invoiceDetail?.discount)}
                    </p>
                  </div>
                </>
              ) : null}
            </div>
            <div>
              <div className="mb-4">
                <p className="font-bold text-center">Location Name</p>
                <p className="font-light text-center">
                  {invoiceDetail?.location}
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold text-center">PPH 4(2)</p>
                <p className="font-light text-center">
                  {rupiah(invoiceDetail?.pph42)}
                </p>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <p className="font-bold text-center">Client Name</p>
                <p className="font-light text-center">
                  {invoiceDetail?.client_name}
                </p>
              </div>
              <div className="mb-4">
                <p className="font-bold text-center">VAT</p>
                <p className="font-light text-center">{invoiceDetail?.vat} %</p>
              </div>
            </div>
          </div>
          {/* <div className="divider"></div>
                    <div>
                        <p className='font-bold text-lg mb-3'>Follow Up</p>
                        <div className="overflow-x-auto border border-slate-200 rounded-md">
                            <table className="table w-full">
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Date & Time</th>
                                        <th>Result</th>
                                        <th>Action</th>
                                        <th>Operator</th>
                                    </tr>
                                </thead>
                                <tbody>
                                            {
                                                detailDeals.follow_up.length !== 0 ?
                                                    detailDeals.follow_up.map((data: any, idx: any) => {
                                                        return <tr key={idx}>
                                                            <th />
                                                            <td>{data.follow_up_date}</td>
                                                            <td>{data.result}</td>
                                                            <td>{data.action.description}</td>
                                                            <td>{data.operator.full_name}</td>
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={5} className="text-center">No Data</td>
                                                    </tr>
                                            }
                                        </tbody>
                            </table>
                        </div>
                        <button className='btn btn-sm btn-primary mt-2' type='button'>New Follow Up</button>
                        <button className='btn btn-sm btn-primary mt-2' type='button' onClick={() => toggleModalFollowUp()}>New Follow Up</button>
                    </div> */}
          {/* <div className="divider"></div> */}
          {/* <div> */}
          {/* <p className="font-bold text-lg mb-3">Invoice Reference</p>
            <div className="overflow-x-auto border border-slate-200 rounded-md">
              <table className="table w-full"> */}
          {/* head */}
          {/* <thead>
                  <tr>
                    <th />
                    <th>Invoice ID</th>
                    <th>Location</th>
                    <th>Due Date</th>
                    <th>Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody> */}
          {/* {
                                                detailDeals.follow_up.length !== 0 ?
                                                    detailDeals.follow_up.map((data: any, idx: any) => {
                                                        return  */}
          {/* <tr>
                    <th />
                    <td>{invoiceDetail?.old_invoice_id}</td>
                    <td>{invoiceDetail?.location}</td>
                    <td>{invoiceDetail?.due_date}</td>
                    <td>{rupiah(invoiceDetail?.price)}</td>
                    <td>{invoiceDetail?.status}</td>
                  </tr> */}
          {/* })
                                                    :
                                                    <tr>
                                                        <td colSpan={5} className="text-center">No Data</td>
                                                    </tr>
                                            } */}
          {/* </tbody>
              </table>
            </div>
          </div> */}
          <div className="divider"></div>
          <div>
            <p className="font-bold text-lg mb-3">Items:</p>
            <div>
              <div className="overflow-x-auto border border-slate-200 rounded-md">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th />
                      <th>Product</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDetail?.items?.map((data: any, idx: any) => {
                      return (
                        <tr key={idx}>
                          <th />
                          <td>{data?.product_name}</td>
                          <td>{rupiah(data?.price)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalOptionViewProforma}
        onRequestClose={toggleModalOptionViewProforma}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-5/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnEsc={false}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalOptionViewProforma}
          >
            ✕
          </label>
          <div>
            <h3 className="font-bold text-center">Proforma Invoice</h3>
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 mt-3">
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-pink-700 border-0"
                  }
                  onClick={() => handleSendInvoice(invoiceDetail?.invoice_no)}
                >
                  {isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}
                  &nbsp;&nbsp;Send Invoice
                </button>
              </div>
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-pink-700 border-0"
                  }
                  onClick={() =>
                    toggleModalViewProforma(invoiceDetail?.invoice_no)
                  }
                >
                  <FontAwesomeIcon icon={faFile} />
                  &nbsp;&nbsp;View Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalViewProforma}
        onRequestClose={toggleModalViewProforma}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-10/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnEsc={false}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalViewProforma}
          >
            ✕
          </label>
          <iframe src={pdfProformaInvoice}></iframe>
        </div>
      </Modal>
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          {/* head */}
          <thead className="border border-slate-300">
            <tr>
              <th className="bg-gray-100 text-gray-600">Invoice Id</th>
              <th className="bg-gray-100 text-gray-600">Plan Id</th>
              <th className="bg-gray-100 text-gray-600 max-w-md text-left">
                Name
              </th>
              <th className="bg-gray-100 text-gray-600 text-left">Company</th>
              <th className="bg-gray-100 text-gray-600 text-left">Location</th>
              <th className="bg-gray-100 text-gray-600 text-left">Plan</th>
              <th className="bg-gray-100 text-gray-600">Overdue</th>
              <th className="bg-gray-100 text-gray-600">Date Due</th>
              <th className="bg-gray-100 text-gray-600">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((data: any, idx: any) => {
              return (
                <tr
                  key={idx}
                  className="cursor-pointer text-sm hover:bg-slate-100 border-x-2 border-b-2"
                  onClick={() => toggleModalModalCollection(data)}
                >
                  <td className="text-center">{data?.id}</td>
                  <td className="text-center">
                    {data?.details[0]?.client_plan_id}
                  </td>
                  <td className="text-left">{data?.client?.full_name}</td>
                  <td className="text-left">{data?.client?.company_name}</td>
                  <td className="text-left">{data?.location?.name}</td>
                  <td className="text-left">
                    {data?.details.map((data: any, idx: any) => {
                      return data.product_name;
                    })}
                  </td>
                  <td className="text-center">{data.date_overdue} Day</td>
                  <td className="text-center">{data.date_due}</td>
                  <td className="text-center">{data?.status_invoice}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
