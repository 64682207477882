import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Signature } from '../../components/Signature/Signature'

export const SignaturePage = () => {
    return (
        <>
            <div className='flex h-screen bg-base-100'>
                <div className='m-auto'>
                    <div className="card w-96 bg-base-100 shadow-xl border border-slate-300">
                        <div className="card-body items-center text-center">
                            <h3 className='font-bold text-lg mb-4'>VOffice Agreement Sign</h3>
                            <Signature />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
