import {
  faCircleExclamation,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { CancelationTable } from "../../../../components/Renewal/SoDetailsComponents/Tables/CancelationTable/CancelationTable";
import { CancelationTableLoading } from "../../../../components/Renewal/SoDetailsComponents/Tables/CancelationTableLoading/CancelationTableLoading";
import { decimal } from "../../../../helper/decimal";
import { RootState } from "../../../../store/store";

type InputsSearch = {
  search?: string;
  limit?: string;
  searchType?: string;
};

export const SoCancelation = () => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<InputsSearch>();
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({
    text: "",
    type: "client.full_name",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState({
    from: 0,
    to: 0,
    total: 0,
  });
  const [pageNav, setPageNav] = useState({
    firstPage: 1,
    nextPage: "",
    preffPage: "",
    lastPage: 0,
  });
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_VOX_SO_CANCELATION_LIST as string) +
          `?page=${page}&limit=${limit}&filter[${search.type}]=${search.text}`,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.data.data);
        setTotalData({
          ...totalData,
          from: res.data.data.from,
          to: res.data.data.to,
          total: res.data.data.total,
        });
        setPageNav({
          ...pageNav,
          nextPage: res.data.data.next_page_url,
          preffPage: res.data.data.prev_page_url,
          lastPage: res.data.data.last_page,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [page, limit, search]);

  return (
    <>
      <p className="ml-10 mb-4 font-bold text-xl">
        Cancelation - Serviced Office
      </p>
      <div className="card w-auto bg-base-100 shadow-xl border border-slate-200">
        <div className="card-body">
          <div className="flex flex-col-reverse sm:flex-row justify-between items-center -mt-2">
            <div className="flex flex-row items-center">
              <div className="form-control mr-2">
                <div className="input-group flex flex-row items-center">
                  <p className="mr-2">Show</p>
                  <Controller
                    control={control}
                    name="limit"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <select
                        className="rounded-md border border-slate-300"
                        disabled={isLoading ? true : false}
                        onChange={(selectedOption: any) => {
                          setLimit(selectedOption.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                      </select>
                    )}
                  />
                  <p className="ml-2">Entries</p>
                </div>
              </div>
            </div>
            <div className="form-control w-full max-w-xs mb-2 mt-2 ml-0 lg:ml-2 sm:my-0">
              <div className="input-group">
                <div className="flex flex-col items-center mr-2">
                  <div className="flex flex-nowrap items-center">
                    <Controller
                      control={control}
                      name="search"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <input
                          type="text"
                          placeholder="Search..."
                          className="input input-bordered w-max shadow-lg"
                          {...register("search")}
                          onChange={(selectedOption: any) => {
                            setSearch({
                              ...search,
                              text: selectedOption.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="-ml-8 text-black"
                    />
                  </div>
                </div>
                <Controller
                  control={control}
                  name="searchType"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <select
                      className="select select-bordered shadow-md"
                      {...register("searchType")}
                      onChange={(selectedOption: any) => {
                        setSearch({
                          ...search,
                          type: selectedOption.target.value,
                        });
                      }}
                    >
                      <option selected value="client.full_name">
                        name
                      </option>
                      <option value="client.client_id">client id</option>
                      <option value="client.company_name">company</option>
                    </select>
                  )}
                />
              </div>
              {/* <form onSubmit={handleSubmit(handleSearch)}> */}
              {/* </form> */}
            </div>
          </div>
          {isLoading ? (
            <div>
              <p className="text-sm italic">
                <FontAwesomeIcon icon={faCircleExclamation} />
                &nbsp;Mohon menunggu beberapa detik untuk load data
              </p>
              <div className="mt-2">
                <CancelationTableLoading />
              </div>
            </div>
          ) : (
            <div className="mt-2">
              <CancelationTable data={data} />
            </div>
          )}
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="mb-2 sm:mb-0">
              <p>
                Showing {totalData.from} to {totalData.to} of{" "}
                {decimal(totalData.total)} entries
              </p>
            </div>
            <div className="btn-group">
              <button
                className={
                  !pageNav.preffPage
                    ? `btn btn-sm btn-disabled`
                    : `btn btn-sm bg-gray-700 border-0`
                }
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                «
              </button>
              <button className="btn btn-sm bg-gray-700 border-0">
                Page {page}
              </button>
              <button
                className={
                  !pageNav.nextPage
                    ? `btn btn-sm btn-disabled`
                    : `btn btn-sm bg-gray-700 border-0`
                }
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                »
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
