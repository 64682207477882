import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ClientCollectionTable } from "../../../../components/Renewal/SoDetailsComponents/Tables/ClientCollectionTable/ClientCollectionTable";
import { ClientCollectionTableLoading } from "../../../../components/Renewal/SoDetailsComponents/Tables/ClientCollectionTableLoading/ClientCollectionTableLoading";
import { decimal } from "../../../../helper/decimal";
import { RootState } from "../../../../store/store";
import DatePicker, {
  DayRange,
} from "@amir04lm26/react-modern-calendar-date-picker";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import Select from "react-select";

export interface DataOption {
  value: string;
  label: string;
}

type InputsSearch = {
  search?: string;
  limit?: string;
  location_id: any;
};

export const ClientCollection = () => {
  const token = useSelector((state: RootState) => {
    return state.userData.token;
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<InputsSearch>();
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState({
    from: 0,
    to: 0,
    total: 0,
  });
  const [optionsLocation, setOptionsLocation] = useState<DataOption[]>([]);
  const [pageNav, setPageNav] = useState({
    nextPage: "",
    preffPage: "",
  });
  const [dayRange, setDayRange] = React.useState<DayRange>({
    from: null,
    to: null,
  });
  const renderCustomInput = ({ ref }: any) => (
    <div className="flex flex-nowrap items-center">
      <input
        readOnly
        ref={ref} // necessary
        placeholder="Filter by date..."
        value={
          dayRange.to?.day
            ? `${dayRange.from?.day}/${dayRange.from?.month}/${dayRange.from?.year} - ${dayRange.to?.day}/${dayRange.to?.month}/${dayRange.to?.year}`
            : ""
        }
        className="input input-bordered w-full text-left rounded-md shadow-lg"
        disabled={isLoading ? true : false}
      />
      {dayRange.to?.day ? null : (
        <FontAwesomeIcon icon={faCalendarDays} className="-ml-8 text-black" />
      )}
    </div>
  );

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_VOX_SO_COLLECTION as string) +
          `?page=${page}&search=${search}&limit=${limit}&filter[location_id]=${location} ${
            dayRange.to?.year === undefined
              ? ``
              : `&start=${dayRange.from?.day}/${dayRange.from?.month}/${dayRange.from?.year}&end=${dayRange.to?.day}/${dayRange.to?.month}/${dayRange.to?.year}`
          }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.data.data);
        setTotalData({
          ...totalData,
          from: res.data.data.from,
          to: res.data.data.to,
          total: res.data.data.total,
        });
        setPageNav({
          ...pageNav,
          nextPage: res.data.data.next_page_url,
          preffPage: res.data.data.prev_page_url,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    axios
      .get(process.env.REACT_APP_VOX_LOCATION as string, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptionsLocation((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, limit, search, dayRange.to?.year, location]);

  return (
    <>
      <p className="ml-10 mb-4 font-bold text-xl">
        Client Collection - Serviced Office
      </p>
      <div className="card w-auto bg-base-100 shadow-xl border border-slate-200">
        <div className="card-body">
          <div className="flex flex-col-reverse sm:flex-row justify-between items-center -mt-2">
            <div className="flex flex-row items-center">
              <div className="form-control mr-2">
                <div className="input-group flex flex-row items-center">
                  <p className="mr-2">Show</p>
                  <Controller
                    control={control}
                    name="limit"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <select
                        className="rounded-md border border-slate-300"
                        disabled={isLoading ? true : false}
                        onChange={(selectedOption: any) => {
                          setLimit(selectedOption.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                      </select>
                    )}
                  />
                  <p className="ml-2">Entries</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse sm:flex-row items-center">
              <div className="form-control w-full mb-2 mt-2 ml-0 lg:ml-2 sm:my-0">
                <div className="flex flex-col md:flex-row items-center gap-2 w-full">
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="location_id"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <Select
                          isClearable
                          placeholder="filter by location"
                          value={optionsLocation.find((c) => c.value === value)}
                          name={name}
                          options={optionsLocation}
                          onChange={(selectedOption: any) => {
                            onChange(selectedOption.value);
                            setLocation(selectedOption.value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <DatePicker
                      value={dayRange}
                      onChange={setDayRange}
                      renderInput={renderCustomInput}
                      shouldHighlightWeekends
                    />
                  </div>
                  <div className="flex flex-nowrap items-center w-full">
                    <Controller
                      control={control}
                      name="search"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <input
                          type="text"
                          placeholder="Search by name..."
                          className="input input-bordered w-full max-w-xs shadow-lg"
                          {...register("search")}
                          onChange={(selectedOption: any) => {
                            setSearch(selectedOption.target.value);
                          }}
                        />
                      )}
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="-ml-8 text-black"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {isLoading ? (
              <ClientCollectionTableLoading />
            ) : (
              <ClientCollectionTable data={data} />
            )}
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="mb-2 sm:mb-0">
              <p>
                Showing {totalData.from} to {totalData.to} of{" "}
                {decimal(totalData.total)} entries
              </p>
            </div>
            <div className="btn-group">
              <button
                className={
                  !pageNav.preffPage
                    ? `btn btn-sm btn-disabled`
                    : `btn btn-sm bg-gray-700 border-0`
                }
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                «
              </button>
              <button className="btn btn-sm bg-gray-700 border-0">
                Page {page}
              </button>
              <button
                className={
                  !pageNav.nextPage
                    ? `btn btn-sm btn-disabled`
                    : `btn btn-sm bg-gray-700 border-0`
                }
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                »
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
