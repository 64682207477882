import React from "react";

export const PlanDetailTabsLoading = () => {
  return (
    <>
      <div className="overflow-x-auto border border-slate-200 rounded-md">
        <table className="table w-full">
          {/* head */}
          <thead>
            <tr>
              <th />
              <th>Plan ID</th>
              <th>Plan Name</th>
              <th>Location</th>
              <th>Status</th>
              <th>Invoice Id</th>
              <th>Status Invoice</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th />
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th />
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
