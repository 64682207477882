import React from 'react'

type propsType = {
    status?: string
}

export const ClientBadgeStatus = ({ status }: propsType) => {

    const badgeConditions = (status: any) => {
        if (status === 'active') {
            return `badge badge-success gap-2`
        } else if (status === `followup`) {
            return `badge badge-warning gap-2`
        } else if (status === `followup!`) {
            return `badge bg-red-600 border-0 gap-2`
        }
    }

    return (
        <>
            <div className={badgeConditions(status)}>
                <p className='text-sm md:text-base text-white'>{status}</p>
            </div>
        </>
    )
}
