import {
  faCircleExclamation,
  faFileExcel,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClientPlansCard } from "../../../../components/Renewal/ClientPlans/ClientPlansCard/ClientPlansCard";
import { ClientPlansCardLoading } from "../../../../components/Renewal/ClientPlans/ClientPlansCardLoading/ClientPlansCardLoading";
import { RootState } from "../../../../store/store";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, {
  DayRange,
} from "@amir04lm26/react-modern-calendar-date-picker";
import { decimal } from "../../../../helper/decimal";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { Controller, useForm } from "react-hook-form";

type InputsSearch = {
  search?: string;
  limit?: string;
  searchType?: string;
};

export const VoData = () => {
  const token = useSelector((state: RootState) => {
    return state.userData.token;
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState({
    from: 0,
    to: 0,
    total: 0,
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<InputsSearch>();
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({
    text: "",
    type: "client.full_name",
  });
  const [pageNav, setPageNav] = useState({
    firstPage: 1,
    nextPage: "",
    preffPage: "",
    lastPage: 0,
  });
  const [dayRange, setDayRange] = React.useState<DayRange>({
    from: null,
    to: null,
  });
  const renderCustomInput = ({ ref }: any) => (
    <div className="flex flex-nowrap items-center">
      <input
        readOnly
        ref={ref} // necessary
        placeholder="Filter by date..."
        value={
          dayRange.to?.day
            ? `${dayRange.from?.day}/${dayRange.from?.month}/${dayRange.from?.year} - ${dayRange.to?.day}/${dayRange.to?.month}/${dayRange.to?.year}`
            : ""
        }
        className="input input-bordered w-full text-left rounded-md shadow-lg"
        disabled={isLoading ? true : false}
      />
      {dayRange.to?.day ? null : (
        <FontAwesomeIcon icon={faCalendarDays} className="-ml-8 text-black" />
      )}
    </div>
  );

  useEffect(() => {}, [dayRange]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_VOX_RENEWAL_VO_DATA as string) +
          `?page=${page}&limit=${limit}&filter[${search.type}]=${search.text}
            ${
              dayRange.to?.year
                ? `&start=${dayRange.from?.day}/${dayRange.from?.month}/${dayRange.from?.year}&end=${dayRange.to?.day}/${dayRange.to?.month}/${dayRange.to?.year}`
                : ""
            }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.data.data);
        setTotalData({
          ...totalData,
          from: res.data.data.from,
          to: res.data.data.to,
          total: res.data.data.total,
        });
        setPageNav({
          ...pageNav,
          nextPage: res.data.data.next_page_url,
          preffPage: res.data.data.prev_page_url,
          lastPage: res.data.data.last_page,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [page, limit, search, dayRange]);

  return (
    <>
      <p className="ml-10 mb-4 font-bold text-xl">
        Client Renewal - Virtual Office
      </p>
      <div className="card w-auto bg-base-100 shadow-xl border border-slate-200">
        <div className="card-body">
          <div className="flex flex-col-reverse sm:flex-row justify-between items-center -mt-2">
            <div className="flex flex-row items-center">
              <div className="form-control mr-2">
                <div className="input-group flex flex-row items-center">
                  <p className="mr-2">Show</p>
                  <Controller
                    control={control}
                    name="limit"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <select
                        className="rounded-md border border-slate-300"
                        disabled={isLoading ? true : false}
                        onChange={(selectedOption: any) => {
                          setLimit(selectedOption.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                      </select>
                    )}
                  />
                  <p className="ml-2">Entries</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse sm:flex-row items-center">
              <div className="mb-2 mx-2 sm:my-0 my-2">
                <a
                  className={
                    isLoading
                      ? `btn btn-disabled shadow-lg`
                      : `btn bg-green-700 border-0 text-white shadow-lg`
                  }
                  href={
                    (process.env.REACT_APP_VOX_EXCEL_VO_DATA as string) +
                    `?page=${page}&limit=${limit}&filter[${search.type}]=${
                      search.text
                    }
            ${
              dayRange.to?.year
                ? `&start=${dayRange.from?.day}/${dayRange.from?.month}/${dayRange.from?.year}&end=${dayRange.to?.day}/${dayRange.to?.month}/${dayRange.to?.year}`
                : ""
            }`
                  }
                  target="_blank"
                >
                  <div className="flex flex-row items-center">
                    <div>
                      <FontAwesomeIcon icon={faFileExcel} size="lg" />
                    </div>
                    <div>&nbsp;&nbsp;Export</div>
                  </div>
                </a>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="w-max mx-auto">
                  <DatePicker
                    value={dayRange}
                    onChange={setDayRange}
                    renderInput={renderCustomInput}
                    shouldHighlightWeekends
                  />
                </div>
                <div className="form-control w-full max-w-xs mb-2 mt-2 ml-0 lg:ml-2 sm:my-0">
                  <div className="input-group">
                    <div className="flex flex-col items-center mr-2">
                      <div className="flex flex-nowrap items-center">
                        <Controller
                          control={control}
                          name="search"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <input
                              type="text"
                              placeholder="Search..."
                              className="input input-bordered w-max shadow-lg"
                              {...register("search")}
                              onChange={(selectedOption: any) => {
                                setSearch({
                                  ...search,
                                  text: selectedOption.target.value,
                                });
                              }}
                            />
                          )}
                        />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="-ml-8 text-black"
                        />
                      </div>
                    </div>
                    <Controller
                      control={control}
                      name="searchType"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <select
                          className="select select-bordered shadow-md"
                          {...register("searchType")}
                          onChange={(selectedOption: any) => {
                            setSearch({
                              ...search,
                              type: selectedOption.target.value,
                            });
                          }}
                        >
                          <option selected value="client.full_name">
                            name
                          </option>
                          <option value="client.client_id">client id</option>
                          <option value="client.company_name">company</option>
                        </select>
                      )}
                    />
                  </div>
                  {/* <form onSubmit={handleSubmit(handleSearch)}> */}
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          <p className="text-sm italic mt-2">
            <FontAwesomeIcon icon={faCircleExclamation} />
            &nbsp;Berikut adalah data 90 hari date due, untuk melihat data
            sebelumnya bisa melalui filter date
          </p>
          <div className="mt-2">
            {isLoading ? (
              <ClientPlansCardLoading />
            ) : (
              <ClientPlansCard data={data} />
            )}
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="mb-2 sm:mb-0">
              <p>
                Showing {totalData.from} to {totalData.to} of{" "}
                {decimal(totalData.total)} entries
              </p>
            </div>
            <div className="btn-group">
              <button
                className={
                  !pageNav.preffPage
                    ? `btn btn-sm btn-disabled`
                    : `btn btn-sm bg-gray-700 border-0`
                }
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                «
              </button>
              <button className="btn btn-sm bg-gray-700 border-0">
                Page {page}
              </button>
              <button
                className={
                  !pageNav.nextPage
                    ? `btn btn-sm btn-disabled`
                    : `btn btn-sm bg-gray-700 border-0`
                }
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                »
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
