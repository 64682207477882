import React from 'react'

type propsType = {
    company_name?: string
    address?: string
    industry?: string
    reg_no?: string
    location?: string
}

export const CompanyInfoTabs = ({ company_name, address, industry, reg_no, location }: propsType) => {
    return (
        <>
            <div className='mx-6 mt-6 mb-3'>
                <p className='font-semibold text-base'>Nama PT : </p>
                <p className='font-normal text-base ml-2'>{company_name}</p>
            </div>
            <div className='mx-6 mt-6 mb-3'>
                <p className='font-semibold text-base'>Address : </p>
                <p className='font-normal text-base ml-2'>{address}</p>
            </div>
            <div className='mx-6 mt-3 mb-3'>
                <p className='font-semibold text-base'>Industry : </p>
                <p className='font-normal text-base ml-2'>{industry}</p>
            </div>
            <div className='mx-6 mt-3 mb-3'>
                <p className='font-semibold text-base'>Reg No : </p>
                <p className='font-normal text-base ml-2'>{reg_no}</p>
            </div>
            <div className='mx-6 mt-3 mb-3'>
                <p className='font-semibold text-base'>Location : </p>
                <p className='font-normal text-base ml-2'>{location}</p>
            </div>
        </>
    )
}
