import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { CalsTableLoading } from './CallsTableLoading/CalsTableLoading';

type propsType = {
    data?: any
}

export const CallsTabs = ({ data }: propsType) => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        axios.get(
            (process.env.REACT_APP_VOX_TABS_DETAIL as string) + `/${data}/calls`,
            {
                headers: {
                    "Authorization": `Bearer ${globalStateUser.token}`
                }
            }
        )
            .then(res => {
                setIsLoading(false)
                setDataTable(res.data.data)
            })
            .catch(error => {
                setIsLoading(true);
                console.log(error)
            })
    }, []);

    return (
        <div>
            {
                isLoading ?
                    <CalsTableLoading />
                    :
                    <div className="overflow-x-auto border border-slate-200 rounded-md">
                        <table className="table w-full">
                            {/* head */}
                            <thead>
                                <tr>
                                    <th />
                                    <th>Call ID</th>
                                    <th>Caller Name</th>
                                    <th>Caller Company Name</th>
                                    <th>Caller No</th>
                                    <th>Message</th>
                                    <th>Urgency Level</th>
                                    <th>Forwarded To</th>
                                    <th>Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataTable.length === 0 ?
                                        <tr>
                                            <th></th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>No Data</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        :
                                        dataTable.map((data: any, idx: any) => {
                                            return <tr key={idx}>
                                                <th />
                                                <td>{data?.id}</td>
                                                <td>{data?.caller_name}</td>
                                                <td>{data?.caller_company_name}</td>
                                                <td>{data?.caller_no}</td>
                                                <td>{data?.message}</td>
                                                <td>{data?.urgency_level}</td>
                                                <td>{data?.forwarded_to}</td>
                                                <td>{data?.note}</td>
                                            </tr>
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}
