/* eslint-disable no-restricted-globals */
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../store/store";
import { faFile, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { rupiah } from "../../../../helper/rupiah";
import { DealInProgressTabsLoading } from "./DealInProgressTabsLoading/DealInProgressTabsLoading";
import { DealInProgressDetailLoading } from "./DealInProgressDetailLoading/DealInProgressDetailLoading";
import { useLocation } from "react-router-dom";
Modal.setAppElement("#root");

type propsType = {
  data?: any;
};

export interface DataOption {
  value: string;
  label: string;
}

type product = {
  id: string;
};

type Inputs = {
  client_id?: string | null;
  location_id?: string;
  products: product[];
  vat?: string;
  pph_42?: boolean;
  pph_23?: boolean;
  promo_id: string;
};

type InputsAgreement = {
  invoice_id?: number;
  did_no?: string;
  location_bank_account_id?: string;
  security_deposit?: number;
  start_date?: string;
  end_date?: string;
  extend_period?: string;
  additional_meeting_room_quota?: number;
  additional_event_space_quota?: number;
  additional_workstation_quota?: number;
  additional_private_office_quota?: number;
};

type GenerateProformaInfoiceType = {
  deal_id: string;
  start_date: string;
  due_date: string;
  contract_term: number;
  vat: boolean;
  pph_42: boolean;
  pph_23: boolean;
  use_renewal_price: string;
};

type NewFollowUp = {
  deals_id: number;
  action: number;
  result: string;
};

export const DealInProgressTabs = ({ data }: propsType) => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const locations = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      products: [
        {
          id: "",
        },
      ],
    },
  });
  const {
    register: registerGPI,
    handleSubmit: handleSubmitGPI,
    reset: resetGPI,
    control: controlGPI,
    formState: { errors: errorsGPI },
  } = useForm<GenerateProformaInfoiceType>();
  const {
    register: registerNFU,
    handleSubmit: handleSubmitNFU,
    reset: resetNFU,
    control: controlNFU,
    formState: { errors: errorsNFU },
  } = useForm<NewFollowUp>();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModalViewProforma, setIsOpenModalViewProforma] = useState(false);
  const [pdfProformaInvoice, setPdfProformaInvoice] = useState("");
  const [isOpenModalNewDeals, setIsOpenModalNewDeals] = useState(false);
  const [
    isOpenModalGenerateProformaInvoice,
    setIsOpenModalGenerateProformaInvoice,
  ] = useState(false);
  const [isOpenModalFollowUp, setIsOpenModalFollowUp] = useState(false);
  const [detailDeals, setDetailDeals] = useState({
    deal_id: 0,
    invoice_id: 0,
    follow_up: [],
    details: [],
    status_deal: "",
    promo: {
      price_cut_type: "",
      price_cut: 0,
      promo_name: "",
      promo_description: "",
    },
  });
  const [pdfAgreement, setPdfAgreement] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [optionsLocation, setOptionsLocation] = useState<DataOption[]>([]);
  const [optionsPromo, setOptionsPromo] = useState<DataOption[]>([]);
  const {
    fields: fieldProduct,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: "products",
  } as never);
  const [locationForSearchProduct, setLocationForSearchProduct] = useState(0);
  const {
    register: registerGA,
    handleSubmit: handleSubmitGA,
    reset: resetGA,
    control: controlGA,
    formState: { errors: errorsGA },
    setValue: setValueGa,
  } = useForm<InputsAgreement>({
    defaultValues: {
      additional_event_space_quota: 0,
      additional_meeting_room_quota: 0,
      additional_private_office_quota: 0,
      additional_workstation_quota: 0,
    },
  });
  const [optionsLocationBankAccount, setOptionsLocationBankAccount] = useState<
    DataOption[]
  >([]);
  const [agreementDetails, setAgreementDetails] = useState({
    statusMessage: "",
  });
  const [isOpenOptionDraftAgreement, setIsOpenOptionDraftAgreement] =
    useState(false);
  function toggleModalOptionDraftAgreement(data?: any) {
    setIsOpenOptionDraftAgreement(!isOpenOptionDraftAgreement);
  }
  const [isOpenViewAgreement, setIsOpenViewAgreement] = useState(false);
  function toggleModalViewAgreement(data?: any) {
    setIsOpenViewAgreement(!isOpenViewAgreement);
    const url =
      location.pathname === "/vo-activation"
        ? (process.env.REACT_APP_VOX_PREVIEW_AGREEMENT_VO as string) +
          `${data}/check`
        : (process.env.REACT_APP_VOX_PREVIEW_AGREEMENT_SO as string) +
          `${data}/check`;
    if (!isOpenViewAgreement) {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        })
        .then((res) => {
          if (res.data.message === "exist") {
            setPdfAgreement(res.data?.data?.url);
          } else if (res.data.message === "draft") {
            setPdfAgreement(res.data?.data?.url);
          } else {
            Swal.fire({
              title: "Agreement is being generated!",
              timer: 20000,
            });
            Swal.showLoading();
            setIsOpenViewAgreement(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  }

  const fetchData = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_VOX_PRODUCT as string) +
            `?filter[location_id]=${locationForSearchProduct}&filter[product_name]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.product_name}`,
                value: element.product_id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_VOX_TABS_DETAIL as string) + `/${data}/deals`,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setDataTable(response.data.data);
      })
      .catch((error) => {
        setIsLoading(true);
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_VOX_LOCATION as string, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptionsLocation((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_VOX_PROMO as string, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptionsPromo((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.promo_name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function toggleModal(data?: any) {
    if (!isOpen) {
      setIsLoading(true);
      axios
        .get(
          (process.env.REACT_APP_VOX_NEW_DEAL as string) + `/${data.deal_id}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((res) => {
          setDetailDeals({
            ...detailDeals,
            deal_id: res.data.data.deal_id,
            invoice_id: res.data.data.invoice_id,
            follow_up: res.data.data.follow_up,
            details: res.data.data.details,
            status_deal: res.data.data.status_deal,
            promo: res.data.data.promo,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
      axios
        .get(
          (process.env.REACT_APP_VOX_PREVIEW_AGREEMENT_SO as string) +
            `${data?.invoice_id}/check`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((res) => {
          setAgreementDetails({
            ...agreementDetails,
            statusMessage: res.data.message,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
    setIsOpen(!isOpen);
  }

  function toggleModalNewDeals(data?: any) {
    setIsOpenModalNewDeals(!isOpenModalNewDeals);
  }

  function toggleModalGenerateProformaInfoice(data?: any) {
    setIsOpenModalGenerateProformaInvoice(!isOpenModalGenerateProformaInvoice);
  }

  function toggleModalFollowUp(data?: any) {
    setIsOpenModalFollowUp(!isOpenModalFollowUp);
  }

  function toggleModalViewProforma(data?: any) {
    setIsOpenModalViewProforma(!isOpenModalViewProforma);
    if (!isOpenModalViewProforma) {
      axios
        .get(
          (process.env.REACT_APP_VOX_PREVIEW_PROFORMA_INVOICE as string) +
            `${data}/check`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.message === "exist") {
            setPdfProformaInvoice(res.data?.data?.url);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${res.data.message}`,
            });
            setIsOpenModalViewProforma(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  }

  const [isOpenGenerateAgreement, setIsOpenGenerateAgreement] = useState(false);
  function toggleModalGenerateAgreement(data?: any) {
    setIsOpenGenerateAgreement(!isOpenGenerateAgreement);
    if (!isOpenGenerateAgreement) {
      setIsLoading(true);
      axios
        .get((process.env.REACT_APP_VOX_INVOICE as string) + data, {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setValueGa("start_date", response.data?.data?.period_from);
          setValueGa("end_date", response.data?.data?.period_to);
          response.data?.data?.location?.banks?.forEach((data: any) => {
            setOptionsLocationBankAccount((prevState) => [
              ...prevState,
              {
                value: data.id,
                label: data.bank_name,
              },
            ]);
          });
        })
        .catch((error) => {
          setIsLoading(true);
          console.log(error);
        });
    } else {
      setOptionsLocationBankAccount([]);
    }
  }

  const handleGenerateAgreement: SubmitHandler<InputsAgreement> = (data) => {
    setIsLoading(true);
    const dataGenerateAggrement = {
      invoice_id: detailDeals.invoice_id,
      did_no: data.did_no,
      location_bank_account_id: Number(data.location_bank_account_id),
      security_deposit: Number(data.security_deposit),
      start_date: data.start_date,
      end_date: data.end_date,
      extend_period: data.extend_period ? data.extend_period : 0,
      additional_meeting_room_quota: Number(data.additional_meeting_room_quota),
      additional_workstation_quota: Number(data.additional_workstation_quota),
      additional_event_space_quota: Number(data.additional_event_space_quota),
      additional_private_office_quota: Number(
        data.additional_private_office_quota
      ),
    };
    axios
      .post(
        process.env.REACT_APP_VOX_GENERATE_AGREEMENT as string,
        dataGenerateAggrement,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire("Agrement Generated!", "", "success");
        setIsLoading(false);
        location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleNewDeal: SubmitHandler<Inputs> = (data) => {
    setIsLoading(true);
    const newDealsData = {
      client_id: globalStateUser.clientId,
      products: Object.values(
        data.products.map((data: any) => {
          return { id: data.id.value };
        })
      ),
      promo_id: data.promo_id,
    };
    axios
      .post(process.env.REACT_APP_VOX_NEW_DEAL as string, newDealsData, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        Swal.fire("Saved!", "", "success");
        location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleGenerateProforma: SubmitHandler<GenerateProformaInfoiceType> = (
    data
  ) => {
    setIsLoading(true);
    const postGenerateProformaInvoiceData = {
      deal_id: detailDeals.deal_id,
      start_date: data.start_date,
      due_date: data.due_date,
      contract_term: Number(data.contract_term),
      vat: data.vat === true ? "y" : "n",
      pph_42: data.pph_42 === true ? "y" : "n",
      pph_23: data.pph_23 === true ? "y" : "n",
      use_renewal_price: data.use_renewal_price,
    };
    axios
      .post(
        process.env.REACT_APP_VOX_GENERATE_PROFORMA_INVOICE as string,
        postGenerateProformaInvoiceData,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        Swal.fire("Invoice Generated!", "", "success");
        location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleNewFollowUp: SubmitHandler<NewFollowUp> = (data) => {
    setIsLoading(true);
    const newFollowUpData = {
      deals_id: detailDeals.deal_id,
      action: Number(data.action),
      result: data.result,
    };
    axios
      .post(
        process.env.REACT_APP_VOX_NEW_FOLLOW_UP as string,
        newFollowUpData,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        toggleModalFollowUp();
        toggleModal();
        Swal.fire("Follow Up Send!", "", "success");
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleSendDraftAgreement = (invoiceId: any) => {
    setIsLoading(true);
    const dataPost = {
      invoice_id: invoiceId,
    };
    axios
      .post(
        process.env.REACT_APP_VOX_SEND_DRAFT_AGREEMENT as string,
        dataPost,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.data) {
          Swal.fire({
            title: "Agreement is being generated!",
            timer: 20000,
          });
          Swal.showLoading();
          setIsLoading(false);
        } else {
          Swal.fire("Draft Agreement Send!", "", "success");
          setIsLoading(false);
        }
        // navigate('/vo-data')
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleCancel = (data: any) => {
    Swal.fire({
      title: "Cancel Plan",
      text: "Enter reason for cancel this plan",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Proceed",
      showLoaderOnConfirm: true,
      preConfirm: (message) => {
        const dataForCancel = {
          deal_id: data,
          cancel_reason: message,
        };
        return axios
          .patch(process.env.REACT_APP_CANCEL_DEAL as string, dataForCancel, {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          })
          .then((res) => {
            Swal.fire("Deal canceled!", "", "success");
            location.reload();
          })
          .catch((res) => {
            Swal.showValidationMessage(`An Error Occured`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-8/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        {isLoading ? (
          <DealInProgressDetailLoading />
        ) : (
          <div className="p-4">
            <label
              htmlFor="my-modal-3"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={toggleModal}
            >
              ✕
            </label>
            <h3 className="text-xl font-bold">Deal In Progress</h3>
            <div className="divider"></div>
            {detailDeals.invoice_id ? (
              <div className="flex flex-col md:flex-row justify-between">
                <div className="flex flex-col md:flex-row gap-2">
                  <button
                    className="btn btn-sm bg-pink-700 border-0"
                    onClick={() =>
                      toggleModalViewProforma(detailDeals.invoice_id)
                    }
                  >
                    <FontAwesomeIcon icon={faFile} />
                    &nbsp;&nbsp;View Proforma Invoice
                  </button>
                  {location.pathname ===
                  "/vo-detail" ? null : agreementDetails.statusMessage ===
                      "draft" ||
                    agreementDetails.statusMessage ===
                      "agreement-so being created" ? (
                    <button
                      className="btn btn-sm bg-slate-500 text-white border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                      onClick={toggleModalOptionDraftAgreement}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                      &nbsp;&nbsp;Draft Agreement File
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm bg-black text-white border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                      onClick={() =>
                        toggleModalGenerateAgreement(detailDeals.invoice_id)
                      }
                    >
                      <FontAwesomeIcon icon={faFile} />
                      &nbsp;&nbsp;Generate Agreement File
                    </button>
                  )}
                </div>
                <div>
                  <button
                    className="btn btn-sm bg-red-700 text-black border-0 mr-0 mb-2 md:mr-2 md:mb-0 w-full mt-2 md:mt-0"
                    onClick={() =>
                      //   toggleModalGenerateAgreement(detailDeals.invoice_id)
                      handleCancel(detailDeals.deal_id)
                    }
                  >
                    <FontAwesomeIcon icon={faFile} />
                    &nbsp;&nbsp;Cancel Plan
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row justify-between">
                <div>
                  <button
                    className="btn btn-sm bg-pink-700 border-0"
                    onClick={() =>
                      toggleModalGenerateProformaInfoice(detailDeals)
                    }
                  >
                    <FontAwesomeIcon icon={faFile} />
                    &nbsp;&nbsp;Generate Proforma Invoice
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm bg-red-700 text-black border-0 mr-0 mb-2 md:mr-2 md:mb-0 w-full mt-2 md:mt-0"
                    onClick={() =>
                      //   toggleModalGenerateAgreement(detailDeals.invoice_id)
                      handleCancel(detailDeals.deal_id)
                    }
                  >
                    <FontAwesomeIcon icon={faFile} />
                    &nbsp;&nbsp;Cancel Plan
                  </button>
                </div>
              </div>
            )}
            <div className="divider"></div>
            <div>
              <p className="font-bold text-lg mb-3">Follow Up</p>
              <div className="overflow-x-auto border border-slate-200 rounded-md">
                <table className="table w-full">
                  {/* head */}
                  <thead>
                    <tr>
                      <th />
                      <th>Date & Time</th>
                      <th>Result</th>
                      <th>Action</th>
                      <th>Operator</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailDeals.follow_up.length !== 0 ? (
                      detailDeals.follow_up.map((data: any, idx: any) => {
                        return (
                          <tr key={idx}>
                            <th />
                            <td>{data.follow_up_date}</td>
                            <td>{data.result}</td>
                            <td>{data.action.description}</td>
                            <td>{data.operator.full_name}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <button
                className="btn btn-sm btn-primary mt-2"
                type="button"
                onClick={() => toggleModalFollowUp()}
              >
                New Follow Up
              </button>
            </div>
            <div className="divider"></div>
            <p className="font-bold text-lg mb-3">Products</p>
            <div className="overflow-x-auto border border-slate-200 rounded-md">
              <table className="table w-full">
                {/* head */}
                <thead>
                  <tr>
                    <th />
                    <th>Product Name</th>
                    <th>Product Category</th>
                    <th>Location</th>
                    <th>Normal Price</th>
                    <th>Renewal Price</th>
                  </tr>
                </thead>
                <tbody>
                  {detailDeals.details.map((data: any, idx: any) => {
                    return (
                      <tr key={idx}>
                        <th />
                        <td>{data?.product?.product_name}</td>
                        <td>{data?.product?.category?.category_name}</td>
                        <td>{data?.product?.location?.name}</td>
                        <td>{rupiah(data?.product?.price)}</td>
                        <td>{rupiah(data?.product?.renewal_price)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="divider"></div>
            <p className="font-bold text-lg mb-3">Promo</p>
            <div>
              {detailDeals.promo ? (
                <div className="card lg:card-side bg-base-100 shadow-xl border border-slate-200 rounded-md">
                  <div className="p-4 flex">
                    <div className="m-auto text-center">
                      <p className="font-bold">PRICE CUT!</p>
                      {detailDeals?.promo.price_cut_type === "1" ? (
                        <p className="font-normal">
                          {rupiah(detailDeals?.promo?.price_cut)}
                        </p>
                      ) : (
                        <p className="font-normal">
                          {detailDeals?.promo?.price_cut}%
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="card-body border-l-2">
                    <h2 className="font-bold">Promo Name : </h2>
                    <h2 className="card-title">
                      {detailDeals?.promo?.promo_name}
                    </h2>
                    <p className="font-normal">Description</p>
                    <p>{detailDeals?.promo?.promo_description}</p>
                  </div>
                </div>
              ) : (
                <div className="card lg:card-side bg-base-100 shadow-xl border border-slate-200 rounded-md">
                  <div className="p-4 flex">
                    <div className="m-auto">
                      <div className="font-bold">
                        <p className="text-center">
                          NO PROMO INCLUDED IN THIS DEAL
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={isOpenOptionDraftAgreement}
        onRequestClose={toggleModalOptionDraftAgreement}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalOptionDraftAgreement}
          >
            ✕
          </label>
          <div>
            <h3 className="font-bold text-center">Draft Agreement File</h3>
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 mt-3">
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-slate-500 text-white border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                  }
                  onClick={() =>
                    handleSendDraftAgreement(detailDeals?.invoice_id)
                  }
                >
                  {isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}
                  &nbsp;&nbsp;Send Draft Agreement File
                </button>
              </div>
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-slate-500 text-white border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                  }
                  onClick={() =>
                    toggleModalViewAgreement(detailDeals?.invoice_id)
                  }
                >
                  <FontAwesomeIcon icon={faFile} />
                  &nbsp;&nbsp;View Draft Agreement File
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalNewDeals}
        onRequestClose={toggleModalNewDeals}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalNewDeals}
          >
            ✕
          </label>
          <h3 className="text-xl font-bold">Add New Deals</h3>
          <div className="divider"></div>
          <div>
            <form onSubmit={handleSubmit(handleNewDeal)}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Location</span>
                </label>
                <Controller
                  control={control}
                  name="location_id"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      value={optionsLocation.find((c) => c.value === value)}
                      name={name}
                      options={optionsLocation}
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption.value);
                        setLocationForSearchProduct(selectedOption.value);
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  )}
                />
                {errors.location_id ? (
                  <span className="text-sm text-red-700">
                    Location is required!
                  </span>
                ) : null}
                {fieldProduct.map((field, index) => {
                  return (
                    <div key={field.id}>
                      <div className="flex flex-row justify-between my-2">
                        <div>
                          <span className="label-text">
                            {index === 1 ? `Product (Membership)` : `Product`}
                          </span>
                        </div>
                        <div>
                          {index > 0 ? null : fieldProduct.length ===
                            2 ? null : (
                            <button
                              type="button"
                              className="btn btn-sm bg-pink-700 border-0"
                              onClick={() =>
                                appendProduct({
                                  id: "",
                                })
                              }
                            >
                              Add Membership
                            </button>
                          )}
                        </div>
                      </div>
                      <Controller
                        control={control}
                        {...register(`products.${index}.id`)}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <AsyncSelect
                            noOptionsMessage={() => "Type to search a product"}
                            isDisabled={
                              !locationForSearchProduct ? true : false
                            }
                            value={value}
                            loadOptions={fetchData}
                            placeholder={`Product`}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        )}
                      />
                      {errors.products ? (
                        <span className="text-sm text-red-700">
                          Products is required!
                        </span>
                      ) : null}
                      {index === 0 ? null : (
                        <div className="mt-2 float-right">
                          <button
                            type="button"
                            onClick={() => removeProduct(index)}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="text-red-700"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
                <label className="label">
                  <span className="label-text">Promo</span>
                </label>
                <Controller
                  control={control}
                  name="promo_id"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      value={optionsPromo.find((c) => c.value === value)}
                      name={name}
                      options={optionsPromo}
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption.value);
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  )}
                />
                <button
                  className={
                    isLoading
                      ? "btn loading mt-5"
                      : "btn bg-pink-700 border-0 mt-5"
                  }
                  type="submit"
                >
                  Create Deals
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalGenerateProformaInvoice}
        onRequestClose={toggleModalGenerateProformaInfoice}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalGenerateProformaInfoice}
          >
            ✕
          </label>
          <h3 className="text-xl font-bold">Generate Proforma Invoice</h3>
          <div className="divider"></div>
          <form onSubmit={handleSubmitGPI(handleGenerateProforma)}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Created Date</span>
              </label>
              <input
                type="date"
                className={`${
                  !errorsGPI.start_date?.type
                    ? `input input-bordered text-black w-full`
                    : `input border-2 border-error text-black w-full`
                }`}
                {...registerGPI("start_date", { required: true })}
              />
              <span className="text-error text-sm font-bold">
                {errorsGPI.start_date?.type === "required" && "Field required"}
              </span>
              <label className="label">
                <span className="label-text">Due Date</span>
              </label>
              <input
                type="date"
                className={`${
                  !errorsGPI.due_date?.type
                    ? `input input-bordered text-black w-full`
                    : `input border-2 border-error text-black w-full`
                }`}
                {...registerGPI("due_date", { required: true })}
              />
              <span className="text-error text-sm font-bold">
                {errorsGPI.due_date?.type === "required" && "Field required"}
              </span>
              <label className="label">
                <span className="label-text">Contract Term</span>
              </label>
              <input
                type="number"
                className={`${
                  !errorsGPI.contract_term?.type
                    ? `input input-bordered text-black w-full`
                    : `input border-2 border-error text-black w-full`
                }`}
                {...registerGPI("contract_term", { required: true })}
              />
              <span className="text-error text-sm font-bold">
                {errorsGPI.contract_term?.type === "required" &&
                  "Field required"}
              </span>
              <label className="label">
                <span className="label-text">Use Renewal Price</span>
              </label>
              <select
                className={`${
                  !errorsGPI.use_renewal_price?.type
                    ? `select select-bordered`
                    : `select select-error`
                }`}
                {...registerGPI("use_renewal_price", { required: true })}
              >
                <option selected value="">
                  Pick one
                </option>
                <option value="y">Yes</option>
                <option value="n">No</option>
              </select>
              <span className="text-error text-sm font-bold">
                {errorsGPI.use_renewal_price?.type === "required" &&
                  "Field required"}
              </span>
              <div className="mt-4 flex flex-row">
                <div className="flex flex-row items-start">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-secondary"
                    checked={true}
                    {...registerGPI("vat")}
                  />
                  <span className="label-text ml-1">VAT</span>
                </div>
                <div className="flex flex-row items-start ml-3">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-secondary"
                    {...registerGPI("pph_42")}
                  />
                  <span className="label-text ml-1">PPh 4(2)</span>
                </div>
                <div className="flex flex-row items-start ml-3">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-secondary"
                    {...registerGPI("pph_23")}
                  />
                  <span className="label-text ml-1">PPh 23</span>
                </div>
              </div>
            </div>
            <button
              className={
                isLoading
                  ? "btn loading w-full mt-4"
                  : "btn bg-pink-700 border-0 text-white w-full mt-4"
              }
              type="submit"
            >
              Generate
            </button>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalFollowUp}
        onRequestClose={toggleModalFollowUp}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalFollowUp}
          >
            ✕
          </label>
          <h3 className="text-xl font-bold">New Follow Up</h3>
          <div className="divider"></div>
          <form onSubmit={handleSubmitNFU(handleNewFollowUp)}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Action</span>
              </label>
              <select
                className={`${
                  !errorsNFU.action?.type
                    ? `input input-bordered text-black w-full`
                    : `input border-2 border-error text-black w-full`
                }`}
                {...registerNFU("action", { required: true })}
              >
                <option selected value="">
                  Pick one
                </option>
                <option value="1">Phone</option>
                <option value="2">E-mail</option>
                <option value="3">WhatsApp Chat</option>
              </select>
              <span className="text-error text-sm font-bold">
                {errorsNFU.action?.type === "required" && "Field required"}
              </span>
              <label className="label">
                <span className="label-text">Result</span>
              </label>
              <textarea
                className={`${
                  !errorsNFU.result?.type
                    ? `textarea textarea-bordered`
                    : `textarea textarea-error`
                }`}
                placeholder="Message"
                {...registerNFU("result", { required: true })}
              ></textarea>
              <span className="text-error text-sm font-bold">
                {errorsNFU.result?.type === "required" && "Field required"}
              </span>
            </div>
            <button
              className={
                isLoading
                  ? "btn loading w-full mt-2"
                  : "btn bg-pink-700 border-0 text-white w-full mt-2"
              }
              type="submit"
            >
              Follow Up
            </button>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalViewProforma}
        onRequestClose={toggleModalViewProforma}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-10/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnEsc={false}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalViewProforma}
          >
            ✕
          </label>
          <iframe src={pdfProformaInvoice}></iframe>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenGenerateAgreement}
        onRequestClose={toggleModalGenerateAgreement}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        {isLoading ? (
          <div>
            <h3 className="text-lg font-bold text-center">Please Wait...</h3>
          </div>
        ) : (
          <div>
            <label
              htmlFor="my-modal-3"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={toggleModalGenerateAgreement}
            >
              ✕
            </label>
            <h3 className="text-xl font-bold">Generate Agreement</h3>
            <div className="divider"></div>
            <form onSubmit={handleSubmitGA(handleGenerateAgreement)}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">DID Number</span>
                </label>
                <input
                  type="number"
                  placeholder="DID number..."
                  className="input input-bordered w-full"
                  {...registerGA("did_no")}
                />
                <label className="label">
                  <span className="label-text">Location Bank Account</span>
                </label>
                <Controller
                  control={controlGA}
                  name="location_bank_account_id"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      value={optionsLocationBankAccount.find(
                        (c) => c.value === value
                      )}
                      name={name}
                      options={optionsLocationBankAccount}
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption.value);
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  )}
                />
                {errorsGA.location_bank_account_id ? (
                  <span className="text-sm text-red-700">Field required</span>
                ) : null}
                <label className="label">
                  <span className="label-text">Security Deposit</span>
                </label>
                <input
                  type="number"
                  placeholder="Security Deposit..."
                  className="input input-bordered w-full"
                  {...registerGA("security_deposit")}
                />
                {errorsGA.security_deposit ? (
                  <span className="text-sm text-red-700">Field required</span>
                ) : null}
                <label className="label">
                  <span className="label-text">Start Date</span>
                </label>
                <input
                  type="date"
                  className={`${
                    !errorsGA.start_date?.type
                      ? `input input-bordered text-black w-full`
                      : `input border-2 border-error text-black w-full`
                  }`}
                  {...registerGA("start_date", { required: true })}
                  // disabled={true}
                />
                <span className="text-error text-sm font-bold">
                  {errorsGA.start_date?.type === "required" && "Field required"}
                </span>
                <label className="label">
                  <span className="label-text">End Date</span>
                </label>
                <input
                  type="date"
                  className={`${
                    !errorsGA.end_date?.type
                      ? `input input-bordered text-black w-full`
                      : `input border-2 border-error text-black w-full`
                  }`}
                  {...registerGA("end_date", { required: true })}
                  // disabled={true}
                />
                <span className="text-error text-sm font-bold">
                  {errorsGA.end_date?.type === "required" && "Field required"}
                </span>
                <label className="label">
                  <span className="label-text">Extend Period</span>
                </label>
                <select
                  className={`${
                    !errorsGA.extend_period?.type
                      ? `select select-bordered`
                      : `select select-error`
                  }`}
                  {...registerGA("extend_period")}
                >
                  <option selected value="">
                    Pick one
                  </option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Months</option>
                  <option value="3">3 Months</option>
                  <option value="4">4 Months</option>
                  <option value="5">5 Months</option>
                  <option value="6">6 Months</option>
                </select>
                <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                  <div className="w-full">
                    <label className="label">
                      <span className="label-text">
                        Additional Meeting Room Quota
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Additional Meeting Room Quota..."
                      defaultValue={0}
                      className="input input-bordered w-full"
                      {...registerGA("additional_meeting_room_quota")}
                    />
                    <label className="label">
                      <span className="label-text">
                        Additional Event Space Quota
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Additional Event Space Quota..."
                      defaultValue={0}
                      className="input input-bordered w-full"
                      {...registerGA("additional_event_space_quota")}
                    />
                  </div>
                  <div className="w-full">
                    <label className="label">
                      <span className="label-text">
                        Additional Workstation Quota
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Additional Workstation Quota..."
                      defaultValue={0}
                      className="input input-bordered w-full"
                      {...registerGA("additional_workstation_quota")}
                    />
                    <label className="label">
                      <span className="label-text">
                        Additional Private Office Quota
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Additional Private Office Quota..."
                      defaultValue={0}
                      className="input input-bordered w-full"
                      {...registerGA("additional_private_office_quota")}
                    />
                  </div>
                </div>
              </div>
              <div className="float-right mt-4">
                <button
                  className={
                    isLoading ? "btn loading" : "btn bg-pink-700 border-0"
                  }
                  type="submit"
                >
                  Generate
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={isOpenViewAgreement}
        onRequestClose={toggleModalViewAgreement}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-10/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalViewAgreement}
          >
            ✕
          </label>
          <iframe src={pdfAgreement}></iframe>
        </div>
      </Modal>
      {isLoading ? (
        <DealInProgressTabsLoading />
      ) : (
        <div className="overflow-x-auto border border-slate-200 rounded-md mb-3">
          <table className="table w-full">
            {/* head */}
            <thead>
              <tr>
                <th />
                <th>Deals ID</th>
                <th>Invoice Number</th>
                <th>Status</th>
                <th>Sales In Charge</th>
              </tr>
            </thead>
            <tbody>
              {dataTable.map((data: any, idx: any) => {
                return (
                  <tr
                    key={idx}
                    className={
                      data.status_deal === "cancelled" ||
                      data.status_deal === "success"
                        ? ""
                        : "hover cursor-pointer"
                    }
                    onClick={() =>
                      data.status_deal === "cancelled" ||
                      data.status_deal === "success"
                        ? null
                        : toggleModal(data)
                    }
                  >
                    <th
                      className={
                        data.invoice_id
                          ? ""
                          : data.status_deal === "cancelled"
                          ? ""
                          : "bg-blue-100"
                      }
                    />
                    <td
                      className={
                        data.invoice_id
                          ? ""
                          : data.status_deal === "cancelled"
                          ? ""
                          : "bg-blue-100"
                      }
                    >
                      {data.deal_id}
                    </td>
                    <td
                      className={
                        data.invoice_id
                          ? ""
                          : data.status_deal === "cancelled"
                          ? ""
                          : "bg-blue-100"
                      }
                    >
                      {data.invoice_id
                        ? data.invoice_id
                        : data.status_deal === "cancelled"
                        ? "Cancelled"
                        : "Invoice Has Not Been Generated"}
                    </td>
                    <td
                      className={
                        data.invoice_id
                          ? ""
                          : data.status_deal === "cancelled"
                          ? ""
                          : "bg-blue-100"
                      }
                    >
                      {data.status_deal}
                    </td>
                    <td
                      className={
                        data.invoice_id
                          ? ""
                          : data.status_deal === "cancelled"
                          ? ""
                          : "bg-blue-100"
                      }
                    >
                      {data.created_by.full_name}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {location.pathname === "/so-detail" ? null : (
        <div className="form-control">
          <button
            className={
              isLoading
                ? "btn btn-disabled"
                : "btn bg-pink-700 border-0 text-white"
            }
            onClick={() => toggleModalNewDeals()}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            &nbsp;New Deal
          </button>
        </div>
      )}
    </>
  );
};
