import React, { useEffect, useState } from "react";
import DatePicker, {
  DayRange,
} from "@amir04lm26/react-modern-calendar-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

type propsType = {
  dataId?: any;
};

export const ActivityTabs = ({ dataId }: propsType) => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dayRange, setDayRange] = React.useState<DayRange>({
    from: null,
    to: null,
  });
  const [labelMail, setLabelMail] = useState([]);
  const [mail, setMail] = useState([]);
  const [labelBooking, setLabelBooking] = useState([]);
  const [booking, setBooking] = useState([]);
  const [labelCall, setLabelCall] = useState([]);
  const [call, setCall] = useState([]);
  const [period, setPeriod] = useState({
    start: "",
    end: "",
  });
  const dataMail = {
    labels: labelMail,
    datasets: [
      {
        label: "Mail",
        data: mail,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(54, 159, 64, 0.2)",
          "rgba(99, 159, 64, 0.2)",
          "rgba(86, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 159, 64, 1)",
          "rgba(99, 159, 64, 1)",
          "rgba(86, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const optionsMail = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
      },
    },
  };
  const dataBooking = {
    labels: labelBooking,
    datasets: [
      {
        label: "Booking",
        data: booking,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(54, 159, 64, 0.2)",
          "rgba(99, 159, 64, 0.2)",
          "rgba(86, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 159, 64, 1)",
          "rgba(99, 159, 64, 1)",
          "rgba(86, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const optionsBooking = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
      },
    },
  };
  const dataCall = {
    labels: labelCall,
    datasets: [
      {
        label: "Call",
        data: call,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(54, 159, 64, 0.2)",
          "rgba(99, 159, 64, 0.2)",
          "rgba(86, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 159, 64, 1)",
          "rgba(99, 159, 64, 1)",
          "rgba(86, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const optionsCall = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
      },
    },
  };

  useEffect(() => {
    axios
      .get(
        (process.env.REACT_APP_VOX_TABS_DETAIL as string) +
          `/${dataId}/activities${
            dayRange.from?.day === undefined
              ? ``
              : `?start=${
                  dayRange.from?.day +
                  "/" +
                  dayRange.from?.month +
                  "/" +
                  dayRange.from?.year
                }&end=${
                  dayRange.to?.day +
                  "/" +
                  dayRange.to?.month +
                  "/" +
                  dayRange.to?.year
                }`
          }`,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        setLabelMail(res.data.data.mail.map((data: any) => data.date));
        setMail(res.data.data.mail.map((data: any) => data.total));
        setLabelBooking(res.data.data.booking.map((data: any) => data.date));
        setBooking(res.data.data.booking.map((data: any) => data.total));
        setLabelCall(res.data.data.call.map((data: any) => data.date));
        setCall(res.data.data.call.map((data: any) => data.total));
        setPeriod({
          ...period,
          start: res.data.data.period.start,
          end: res.data.data.period.end,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dayRange.to?.year]);

  const renderCustomInput = ({ ref }: any) => (
    <div className="flex flex-nowrap items-center">
      <input
        readOnly
        ref={ref} // necessary
        placeholder="Filter by date..."
        value={
          dayRange.to?.day
            ? `${dayRange.from?.day}/${dayRange.from?.month}/${dayRange.from?.year} - ${dayRange.to?.day}/${dayRange.to?.month}/${dayRange.to?.year}`
            : ""
        }
        className="input input-bordered w-full text-left rounded-md shadow-lg"
        disabled={isLoading ? true : false}
      />
      {dayRange.to?.day ? null : (
        <FontAwesomeIcon icon={faCalendarDays} className="-ml-8 text-black" />
      )}
    </div>
  );

  return (
    <div>
      <p className="text-center">Choose Period</p>
      <div className="w-max mx-auto">
        <DatePicker
          value={dayRange}
          onChange={setDayRange}
          renderInput={renderCustomInput}
          shouldHighlightWeekends
        />
      </div>
      {booking.length === 0 ? null : (
        <div>
          <div className="divider"></div>
          <h3 className="font-bold text-lg mt-3">Booking History</h3>
          <p className="text-sm font-bold text-center">
            Number of Booking on Montly basis from {period?.start} to{" "}
            {period?.end} Total :{" "}
            {booking.reduce((partialSum, a) => partialSum + a, 0)}
          </p>
          <Bar options={optionsBooking} data={dataBooking} />
        </div>
      )}
      {call.length === 0 ? null : (
        <div>
          <div className="divider"></div>
          <h3 className="font-bold text-lg mt-3">Call History</h3>
          <p className="text-sm font-bold text-center">
            Number of Booking on Montly basis from {period?.start} to{" "}
            {period?.end} Total :{" "}
            {call.reduce((partialSum, a) => partialSum + a, 0)}
          </p>
          <Bar options={optionsCall} data={dataCall} />
        </div>
      )}
      {mail.length === 0 ? null : (
        <div>
          <div className="divider"></div>
          <h3 className="font-bold text-lg mt-3">Mail History</h3>
          <p className="text-sm font-bold text-center">
            Number of Booking on Montly basis from {period?.start} to{" "}
            {period?.end} Total :{" "}
            {mail.reduce((partialSum, a) => partialSum + a, 0)}
          </p>
          <Bar options={optionsMail} data={dataMail} />
        </div>
      )}
    </div>
  );
};
