import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ClientTable } from "../../components/ClientsPageComponents/ClientTable/ClientTable";
import { ClientPlansCardLoading } from "../../components/Renewal/ClientPlans/ClientPlansCardLoading/ClientPlansCardLoading";
import { decimal } from "../../helper/decimal";
import { RootState } from "../../store/store";

type InputsSearch = {
  search?: string;
  limit?: string;
};

export const Clients = () => {
  const token = useSelector((state: RootState) => {
    return state.userData.token;
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<InputsSearch>();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState({
    from: 0,
    to: 0,
    total: 0,
  });
  const [pageNav, setPageNav] = useState({
    nextPage: "",
    preffPage: "",
  });
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_LIST_CLIENTS as string) +
          `?page=${page}&limit=${limit}&filter[full_name]=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.data.data);
        setTotalData({
          ...totalData,
          from: res.data.data.from,
          to: res.data.data.to,
          total: res.data.data.total,
        });
        setPageNav({
          ...pageNav,
          nextPage: res.data.data.next_page_url,
          preffPage: res.data.data.prev_page_url,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [page, limit, search]);

  return (
    <>
      <p className="ml-10 mb-4 font-bold text-xl">List Clients</p>
      <div className="card w-auto bg-base-100 shadow-xl border border-slate-200">
        <div className="card-body">
          <div className="flex flex-col-reverse sm:flex-row justify-between items-center -mt-2">
            <div className="flex flex-row items-center">
              <div className="form-control mr-2">
                <div className="input-group flex flex-row items-center">
                  <p className="mr-2">Show</p>
                  <Controller
                    control={control}
                    name="limit"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <select
                        className="rounded-md border border-slate-300"
                        disabled={isLoading ? true : false}
                        onChange={(selectedOption: any) => {
                          setLimit(selectedOption.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                      </select>
                    )}
                  />
                  <p className="ml-2">Entries</p>
                </div>
              </div>
            </div>
            {/* <div className='flex flex-col md:flex-row'> */}
            <div className="flex flex-col items-center mb-2 md:mb-0">
              <div className="flex flex-nowrap items-center">
                <Controller
                  control={control}
                  name="search"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <input
                      type="text"
                      placeholder="Search by name..."
                      className="input input-bordered w-full max-w-xs shadow-lg"
                      {...register("search")}
                      onChange={(selectedOption: any) => {
                        setSearch(selectedOption.target.value);
                      }}
                    />
                  )}
                />
                <FontAwesomeIcon icon={faSearch} className="-ml-8 text-black" />
              </div>
            </div>
            {/* <div className='ml-0 md:ml-2 my-2 md:my-0'>
                                <button className='btn bg-pink-700 text-white border-0 w-full shadow-lg' disabled={isLoading ? true : false} >Add Client</button>
                            </div> */}
            {/* </div> */}
          </div>
          <div className="mt-2">
            {isLoading ? (
              <ClientPlansCardLoading />
            ) : (
              <ClientTable data={data} />
            )}
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="mb-2 sm:mb-0">
              <p>
                Showing {totalData.from} to {totalData.to} of{" "}
                {decimal(totalData.total)} entries
              </p>
            </div>
            <div className="btn-group">
              <button
                className={
                  !pageNav.preffPage
                    ? `btn btn-sm btn-disabled`
                    : `btn btn-sm bg-gray-700 border-0`
                }
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                «
              </button>
              <button className="btn btn-sm bg-gray-700 border-0">
                Page {page}
              </button>
              <button
                className={
                  !pageNav.nextPage
                    ? `btn btn-sm btn-disabled`
                    : `btn btn-sm bg-gray-700 border-0`
                }
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                »
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
