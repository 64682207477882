/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import Swal from 'sweetalert2';
import { RootState } from '../../store/store';

export const Signature = () => {
    // const [dataURL, setDataURL] = React.useState<string | null>(null);
    const { invoiceId } = useParams();

    let padRef = React.useRef<SignatureCanvas>(null);

    const clear = () => {
        padRef.current?.clear();
    };

    const trim = () => {
        const url = padRef.current?.getTrimmedCanvas().toDataURL("image/png");
        const dataPostSignature = {
            signature_base64: url
        }
        if (url) {
            // setDataURL(url)
            Swal.fire({
                title: 'Are you sure you want to submit this signature?',
                showCancelButton: true,
                confirmButtonText: 'Submit',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(
                        (process.env.REACT_APP_VOX_AGREEMENT_SIGNATURE as string) + `${invoiceId}`,
                        dataPostSignature
                    )
                        .then(res => {
                            if (res.data.status === false) {
                                Swal.fire(`${res.data.message}`, '', 'warning')
                            } else {
                                Swal.fire('Signature successfully send!', '', 'success')
                                window.close();
                            }
                        })
                        .catch(error => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!'
                            })
                        })
                    Swal.fire('Saved!', '', 'success')
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })
        };
    };

    return (
        <div>
            <SignaturePad ref={padRef} canvasProps={{ className: "sigCanvas" }} />
            <div className="sigPreview mt-4">
                <button className='btn btn-sm bg-pink-700 border-0 mr-1' onClick={trim}>Submit</button>
                <button className='btn btn-sm bg-slate-500 border-0' onClick={clear}>Clear</button>
            </div>
            {/* {dataURL ? (
          <img
            className={"sigImage"}
            src={dataURL}
            alt="user generated signature"
          />
        ) : null} */}
        </div>
    );
}
