import React from "react";

type propsType = {
  name_plan?: string;
  due_date?: string;
  extend_period?: string;
  event_space?: number;
  meeting_room?: number;
  private_office?: number;
  workstation?: number;
};

export const ClientPlansActiveCard = ({
  name_plan,
  due_date,
  extend_period,
  event_space,
  meeting_room,
  private_office,
  workstation,
}: propsType) => {
  return (
    <>
      <div className="card w-max bg-base-100 shadow-md mx-1 p-4 border border-slate-200">
        <p className="text-sm md:text-base text-center">{name_plan}</p>
        <p className="text-sm md:text-base text-center">
          Due Date : {due_date}
        </p>
        <p className="text-sm md:text-base text-center">
          Extend Period : {extend_period}
        </p>
        <p className="text-sm md:text-base text-center font-bold">Quotas</p>
        <p className="text-sm md:text-base text-center">
          Event Space : {event_space}
        </p>
        <p className="text-sm md:text-base text-center">
          Meeting Room : {meeting_room}
        </p>
        <p className="text-sm md:text-base text-center">
          Private Office : {private_office}
        </p>
        <p className="text-sm md:text-base text-center">
          Workstation : {workstation}
        </p>
      </div>
    </>
  );
};
