import { faAngleLeft, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClientBadgeStatus } from "../../../../components/Renewal/ClientPlans/ClientBadgeStatus/ClientBadgeStatus";
import { ClientPlansActiveCard } from "../../../../components/Renewal/SoDetailsComponents/ClientPlansActiveCard/ClientPlansActiveCard";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { CompanyInfoTabs } from "../../../../components/Renewal/SoDetailsComponents/CompanyInfoTabs/CompanyInfoTabs";
import { PlanDetailTabs } from "../../../../components/Renewal/SoDetailsComponents/PlanDetailTabs/PlanDetailTabs";
import { InvoiceTabs } from "../../../../components/Renewal/SoDetailsComponents/InvoiceTabs/InvoiceTabs";
import { DealInProgressTabs } from "../../../../components/Renewal/SoDetailsComponents/DealInProgressTabs/DealInProgressTabs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import axios from "axios";
import {
  setClientId,
  setClientName,
  setLocationId,
  setLocationName,
  setProduct1,
  setProduct1Name,
  setProduct2,
  setProduct2Name,
  setVat,
} from "../../../../store/userSlice";
import { DetailVoDataLoading } from "../DetailVoDataLoading/DetailVoDataLoading";
import { ReminderTabs } from "../../../../components/Renewal/SoDetailsComponents/ReminderTabs/ReminderTabs";
import { CallsTabs } from "../../../../components/Renewal/SoDetailsComponents/CallsTabs/CallsTabs";
import { MailTabs } from "../../../../components/Renewal/SoDetailsComponents/MailTabs/MailTabs";
import { PicListTabs } from "../../../../components/Renewal/SoDetailsComponents/PicListTabs/PicListTabs";
import { MailHandlingContactTabs } from "../../../../components/Renewal/SoDetailsComponents/MailHandlingContactTabs/MailHandlingContactTabs";
import { ActivityTabs } from "../../../../components/Renewal/SoDetailsComponents/ActivityTabs/ActivityTabs";
import Swal from "sweetalert2";

interface clientDataType {
  full_name: string;
  status_client: string;
  client_id: number;
  company_name: string;
  industry: string;
  email: string;
  phone: string;
  address: string;
  plans: [];
  invoices: [];
  deals: [];
}

export const DetailVoData = () => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const [detailClient, setDetailClient] = useState<clientDataType>();
  const [companyInfoData, setCompanyInfoData] = useState({
    company_name: "",
    address: "",
    industry: "",
    reg_no: "",
    location: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  if (!globalStateUser.clientId) {
    navigate("/clients");
  }

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_VOX_DETAIL_CLIENT_VO as string) +
          globalStateUser.clientId,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        dispatch(setLocationId(res.data?.data?.location_id));
        dispatch(setLocationName(res.data?.data?.location?.name));
        const {
          full_name,
          status_client,
          client_id,
          company_name,
          industry,
          email,
          phone,
          address,
          plans,
          deals,
          invoices,
          company_reg_no,
          location,
        } = res.data.data;
        setDetailClient({
          ...detailClient,
          full_name,
          status_client,
          client_id,
          company_name,
          industry,
          email,
          phone,
          address,
          plans,
          deals,
          invoices,
        });
        setCompanyInfoData({
          ...companyInfoData,
          company_name,
          address: address,
          industry: industry?.industry_name,
          location: location?.name,
          reg_no: company_reg_no,
        });
      })
      .catch((error) => {
        setIsLoading(true);
        console.log(error);
      });

    // Clean Up Function Clear Client Id
    return () => {
      dispatch(setClientId(null));
      dispatch(setClientName(null));
      dispatch(setLocationId(null));
      dispatch(setLocationName(null));
      dispatch(setProduct1(null));
      dispatch(setProduct1Name(null));
      dispatch(setProduct2(null));
      dispatch(setProduct2Name(null));
      dispatch(setVat(null));
    };
  }, []);

  const handleSendPortal = (data: any) => {
    Swal.fire("Loading...");
    Swal.showLoading();
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_VOX_TABS_DETAIL as string) +
          `/${data}/send-portal-access`,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Access portal send!",
        });
        Swal.hideLoading();
      })
      .catch((error) => {
        setIsLoading(true);
        console.log(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <DetailVoDataLoading />
      ) : (
        <>
          <div className="card w-max bg-base-100 shadow-xl mb-2 p-4 border border-slate-200">
            {location.pathname === "/client-detail" ? (
              <Link to="/clients">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className="text-sm md:text-base"
                />
                &nbsp;&nbsp;<span className="text-sm md:text-base">Back</span>
              </Link>
            ) : location.pathname === "/vo-detail" ? (
              <Link to="/vo-data">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className="text-sm md:text-base"
                />
                &nbsp;&nbsp;<span className="text-sm md:text-base">Back</span>
              </Link>
            ) : (
              <Link to="/so-data">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className="text-sm md:text-base"
                />
                &nbsp;&nbsp;<span className="text-sm md:text-base">Back</span>
              </Link>
            )}
          </div>
          <div className="card w-auto bg-base-100 shadow-xl border border-slate-200">
            <div className="card-body">
              <div className="flex flex-col md:flex-row justify-between">
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    size="3x"
                    className="mr-3"
                  />
                  <div>
                    <p className="font-bold text-sm md:text-base">
                      {detailClient?.full_name}{" "}
                      <span>
                        <ClientBadgeStatus
                          status={detailClient?.status_client}
                        />
                      </span>
                    </p>
                    <p className="text-sm md:text-base text-slate-400">
                      ID : {detailClient?.client_id}
                    </p>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-primary mt-2 md:mt-0"
                    onClick={() => handleSendPortal(detailClient?.client_id)}
                  >
                    Send Portal Access
                  </button>
                </div>
              </div>
              <div className="mt-3">
                {/* <p className='font-bold text-base md:text-lg mb-2'>{detailClient?.company_name}</p> */}
                <div className="flex flex-col md:flex-row">
                  <div className="border-8 border-l-pink-700 border-r-0 border-y-0 p-2 mx-2 mb-2 md:mb-0 flex flex-col h-max">
                    <p className="text-sm md:text-base font-bold text-left">
                      e-mail
                    </p>
                    <p className="text-sm md:text-base text-left">
                      {detailClient?.email}
                    </p>
                  </div>
                  <div className="border-8 border-l-pink-700 border-r-0 border-y-0 p-2 mx-2 mb-2 md:mb-0 flex flex-col h-max">
                    <p className="text-sm md:text-base font-bold text-left">
                      phone
                    </p>
                    <p className="text-sm md:text-base text-left">
                      {detailClient?.phone}
                    </p>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div>
                <p className="font-bold text-base md:text-lg mb-2">
                  Plan Active
                </p>
                <div className="mb-2">
                  <div className="carousel carousel-end p-2">
                    {detailClient?.plans?.map((data: any, idx: any) => {
                      return (
                        <div key={idx} className="carousel-item">
                          <ClientPlansActiveCard
                            name_plan={data.product?.product_name}
                            due_date={data.next_renew_date}
                            extend_period={data.extend_period}
                            event_space={data.details[0]?.event_space_quota}
                            meeting_room={data.details[0]?.meeting_room_quota}
                            private_office={
                              data.details[0]?.private_office_quota
                            }
                            workstation={data.details[0]?.workstation_quota}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="divider"></div>
                <div>
                  <Tabs>
                    <TabList>
                      <Tab>Company Info</Tab>
                      <Tab>Plans History</Tab>
                      <Tab>Invoices</Tab>
                      {location.pathname === "/client-detail" ? null : (
                        <Tab>Deal In Progress</Tab>
                      )}
                      <Tab>Calls</Tab>
                      <Tab>Mails</Tab>
                      <Tab>Contact List</Tab>
                      <Tab>Mail Handling Contact</Tab>
                      <Tab>Client Activities</Tab>
                    </TabList>

                    <TabPanel>
                      <CompanyInfoTabs
                        company_name={companyInfoData?.company_name}
                        industry={companyInfoData?.industry}
                        address={companyInfoData?.address}
                        location={companyInfoData?.location}
                        reg_no={companyInfoData?.reg_no}
                      />
                    </TabPanel>
                    <TabPanel>
                      <PlanDetailTabs data={detailClient?.client_id} />
                    </TabPanel>
                    <TabPanel>
                      <InvoiceTabs
                        data={detailClient?.client_id}
                        clientName={detailClient?.full_name}
                        email={detailClient?.email}
                      />
                    </TabPanel>
                    {location.pathname === "/client-detail" ? null : (
                      <TabPanel>
                        <DealInProgressTabs data={detailClient?.client_id} />
                      </TabPanel>
                    )}
                    <TabPanel>
                      <CallsTabs data={detailClient?.client_id} />
                    </TabPanel>
                    <TabPanel>
                      <MailTabs data={detailClient?.client_id} />
                    </TabPanel>
                    <TabPanel>
                      <PicListTabs data={detailClient?.client_id} />
                    </TabPanel>
                    <TabPanel>
                      <MailHandlingContactTabs data={detailClient?.client_id} />
                    </TabPanel>
                    <TabPanel>
                      <ActivityTabs dataId={detailClient?.client_id} />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
