import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

export const DetailVoDataLoading = () => {
    return (
        <>
            <div className='card w-max bg-base-100 shadow-xl mb-2 p-4 border border-slate-200'>
                <div className='text-sm md:text-base' >
                    <div className="animate-pulse flex space-x-4 w-12">
                        <div className="flex-1 space-y-6 py-1">
                            <div className="space-y-3">
                                <div className="h-4 bg-slate-700 rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card w-auto bg-base-100 shadow-xl border border-slate-200'>
                <div className="card-body">
                    <div className='flex flex-row items-center'>
                        <div className="avatar mr-3">
                            <div className="w-14">
                                <div className="animate-pulse flex space-x-4">
                                    <div className="rounded-full bg-slate-700 h-14 w-14" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='font-bold text-sm md:text-base'>
                                <div className='flex flex-row items-center'>
                                    <div className='mr-2'>
                                        <div className="animate-pulse flex space-x-4 w-32">
                                            <div className="flex-1 space-y-6 py-1">
                                                <div className="space-y-3">
                                                    <div className="h-4 bg-slate-700 rounded" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="animate-pulse flex space-x-4 w-12">
                                            <div className="flex-1 space-y-6 py-1">
                                                <div className="space-y-3">
                                                    <div className="h-2 bg-slate-700 rounded" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row items-center'>
                                <div className='mr-2'>
                                    <p className='text-sm md:text-base text-slate-400'>ID :</p>
                                </div>
                                <div>
                                    <div className="animate-pulse flex space-x-4 w-9">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className='font-bold text-base md:text-lg mb-2'>
                            <div className="animate-pulse flex space-x-4 w-28">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-4 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </p>
                        <div className='flex flex-col md:flex-row'>
                            <div className='border border-slate-400 rounded-md p-2 mx-2 mb-2 md:mb-0 flex h-max'>
                                <p className='text-sm md:text-base m-auto text-center'>
                                    <div className="animate-pulse flex space-x-4 w-40">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse flex space-x-4 w-40">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div className='border border-slate-400 rounded-md p-2 mx-2 mb-2 md:mb-0 flex h-max'>
                                <p className='text-sm md:text-base m-auto text-center'>
                                    <div className="animate-pulse flex space-x-4 w-40">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div className='border border-slate-400 rounded-md p-2 mx-2 mb-2 md:mb-0 flex h-max'>
                                <p className='text-sm md:text-base m-auto text-center'>
                                    <div className="animate-pulse flex space-x-4 w-40">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="animate-pulse flex space-x-4 w-40">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div>
                        <p className='font-bold text-base md:text-lg mb-2'>Plan Active</p>
                        <div className='mb-2'>
                            <div className="carousel carousel-end p-2">
                                <div className="carousel-item">
                                    <div className='flex flex-col mx-4'>
                                        <div>
                                            <div className="animate-pulse flex space-x-4 w-44">
                                                <div className="flex-1 space-y-6 py-1">
                                                    <div className="space-y-3">
                                                        <div className="h-4 bg-slate-700 rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="animate-pulse flex space-x-4 w-44">
                                                <div className="flex-1 space-y-6 py-1">
                                                    <div className="space-y-3">
                                                        <div className="h-4 bg-slate-700 rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className='flex flex-col mx-4'>
                                        <div>
                                            <div className="animate-pulse flex space-x-4 w-44">
                                                <div className="flex-1 space-y-6 py-1">
                                                    <div className="space-y-3">
                                                        <div className="h-4 bg-slate-700 rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="animate-pulse flex space-x-4 w-44">
                                                <div className="flex-1 space-y-6 py-1">
                                                    <div className="space-y-3">
                                                        <div className="h-4 bg-slate-700 rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className='flex flex-col mx-4'>
                                        <div>
                                            <div className="animate-pulse flex space-x-4 w-44">
                                                <div className="flex-1 space-y-6 py-1">
                                                    <div className="space-y-3">
                                                        <div className="h-4 bg-slate-700 rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="animate-pulse flex space-x-4 w-44">
                                                <div className="flex-1 space-y-6 py-1">
                                                    <div className="space-y-3">
                                                        <div className="h-4 bg-slate-700 rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className='flex flex-col mx-4'>
                                        <div>
                                            <div className="animate-pulse flex space-x-4 w-44">
                                                <div className="flex-1 space-y-6 py-1">
                                                    <div className="space-y-3">
                                                        <div className="h-4 bg-slate-700 rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="animate-pulse flex space-x-4 w-44">
                                                <div className="flex-1 space-y-6 py-1">
                                                    <div className="space-y-3">
                                                        <div className="h-4 bg-slate-700 rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row'>
                                <div className='mx-4'>
                                    <div className="animate-pulse flex space-x-4 w-14">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-4'>
                                    <div className="animate-pulse flex space-x-4 w-14">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-4'>
                                    <div className="animate-pulse flex space-x-4 w-14">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-4'>
                                    <div className="animate-pulse flex space-x-4 w-14">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-4 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <div className="animate-pulse flex space-x-4 w-full">
                                    <div className="flex-1 space-y-6 py-1">
                                        <div className="space-y-3">
                                            <div className="h-40 bg-slate-700 rounded" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
