import React from 'react'

export const NotFound = () => {
    return (
        <div className='flex h-screen'>
            <div className='m-auto text-pink-700'>
                <h1 className='font-bold text-9xl'>404</h1>
                <p className='font-bold text-3xl'>Page Not Found</p>
            </div>
        </div>
    )
}
