import React from 'react'

export const DetailModalLoading = () => {
    return (
        <div>
            <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
            <div className='flex flex-col md:flex-row items-center'>
                <div className="animate-pulse flex space-x-4 w-2/3">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="h-8 bg-slate-700 rounded" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider"></div>
            <div>
                <h3 className='text-xl font-bold mb-2'>Plan Info</h3>
                <div>
                    <div className="animate-pulse flex space-x-4 w-full">
                        <div className="flex-1 space-y-6 py-1">
                            <div className="space-y-3">
                                <div className="h-14 bg-slate-700 rounded" />
                            </div>
                        </div>
                    </div>
                    <div className="animate-pulse flex space-x-4 w-full">
                        <div className="flex-1 space-y-6 py-1">
                            <div className="space-y-3">
                                <div className="h-14 bg-slate-700 rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
