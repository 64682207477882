import React from "react";

export const PicListTabsLoading = () => {
  return (
    <div className="overflow-x-auto border border-slate-200 rounded-md">
      <table className="table w-full">
        {/* head */}
        <thead>
          <tr>
            <th />
            <th>PIC ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Number</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(2)].map((x, i) => (
            <tr>
              <th />
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-32">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="animate-pulse flex space-x-4 w-9">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                      <div className="h-2 bg-slate-700 rounded" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
