import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setClientId, setClientName } from "../../../../store/userSlice";
import { ClientBadgeStatus } from "../ClientBadgeStatus/ClientBadgeStatus";

type propsType = {
  data?: any[];
};

export const ClientPlansCard = ({ data }: propsType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToDetail = (data?: any) => {
    dispatch(setClientId(data.client_id));
    dispatch(setClientName(data.client.full_name));
    if (location.pathname === "/clients") {
      navigate("/client-detail");
    } else if (location.pathname === "/so-data") {
      navigate("/so-detail");
    } else if (location.pathname === "/vo-data") {
      navigate("/vo-detail");
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        {/* head */}
        <thead className="border border-slate-300">
          <tr>
            {/* <th className='bg-pink-700' /> */}
            <th className="bg-gray-100 text-gray-600 full-width">Client ID</th>
            <th className="bg-gray-100 text-gray-600 max-w-md text-left">
              Name
            </th>
            <th className="bg-gray-100 text-gray-600 text-left">Company</th>
            <th className="bg-gray-100 text-gray-600 text-left">Location</th>
            <th className="bg-gray-100 text-gray-600 ">Plan</th>
            <th className="bg-gray-100 text-gray-600">Overdue</th>
            <th className="bg-gray-100 text-gray-600 full-width">
              Next Renew Date
            </th>
            <th className="bg-gray-100 text-gray-600 full-width">
              Extend Period
            </th>
            <th className="bg-gray-100 text-gray-600">Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((data, idx) => {
            return (
              <>
                <tr
                  className="cursor-pointer text-sm hover:bg-slate-100 border-x-2 border-b-2"
                  key={idx}
                  onClick={() => goToDetail(data)}
                >
                  {/* <th /> */}
                  <td className="text-center">{data?.client_id}</td>
                  <td className="text-left">{data?.client?.full_name}</td>
                  <td className="text-left">{data?.client?.company_name}</td>
                  <td className="text-left">{data?.client?.location?.name}</td>
                  <td className="text-center">{data?.product?.product_name}</td>
                  <td className="text-center">{data?.date_difference} Day</td>
                  <td className="text-center full-width">
                    {data?.next_renew_date}
                  </td>
                  <td className="text-center full-width">
                    {data?.extend_period}
                  </td>
                  <td className="text-center">{data?.status_follow_up}</td>
                  {/* <td className='text-center'><ClientBadgeStatus status={data?.status_follow_up} /></td> */}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
