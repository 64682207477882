/* eslint-disable no-restricted-globals */
import { faCheck, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../../store/store";

type propsType = {
  data?: any[];
};

export const SoRoomTable = ({ data }: propsType) => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });

  const handleDelete = (dataId: any) => {
    const dataDelete = {
      room_id: dataId.room_id,
    };
    Swal.fire({
      title: "Do you want to delete data?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Please wait");
        Swal.showLoading();
        axios
          .delete(process.env.REACT_APP_VOX_SO as string, {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
            data: dataDelete,
          })
          .then((res) => {
            Swal.fire("Data Deleted!", "", "success");
            location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        {/* head */}
        <thead className="border border-slate-300">
          <tr>
            {/* <th className='bg-pink-700' /> */}
            <th className="bg-gray-100 text-gray-600">Room Name</th>
            <th className="bg-gray-100 text-gray-600 max-w-md text-center">
              Location
            </th>
            <th className="bg-gray-100 text-gray-600 text-left">Capacity</th>
            <th className="bg-gray-100 text-gray-600 text-left">Size</th>
            {/* <th className='bg-gray-100 text-gray-600 '>View</th> */}
            <th className="bg-gray-100 text-gray-600">Status</th>
            <th className="bg-gray-100 text-gray-600">Date Created</th>
            {/* <th className='bg-gray-100 text-gray-600 full-width'>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {data?.map((data, idx) => {
            return (
              <>
                <tr className="text-sm border-x-2 border-b-2" key={idx}>
                  {/* <th /> */}
                  <td className="text-center full-width">{data?.room_name}</td>
                  <td className="text-center">{data?.location?.name}</td>
                  <td className="text-center">{data?.room_capacity}</td>
                  <td className="text-center">{data?.room_size}</td>
                  {/* <td className='text-center'>{data?.view === "y" ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}</td> */}
                  <td className="text-center">{data?.status_service_office}</td>
                  <td className="text-center">{data?.created_date}</td>
                  {/* <td className='text-center full-width'>
                                            <button className='btn btn-sm btn-error'><FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(data)} /></button>
                                        </td> */}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
