/* eslint-disable no-restricted-globals */
import axios from "axios";
import AsyncSelect from "react-select/async";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../store/store";
import Swal from "sweetalert2";
import { setClientId } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";

export interface DataOption {
  value: string;
  label: string;
}

type ServicedOfficeType = {
  product_name: string;
  client_id: any;
  location_id: string;
  serviced_office_id: [];
  billing_cycle: number;
  price: number;
  promo_id: string;
  max_contacts: number;
  credit: number;
  meeting_room_quota: number;
  workstation_quota: number;
  event_space_quota: number;
  private_office_quota: number;
  meeting_room_reload: string;
  workstation_reload: string;
  event_space_reload: string;
  private_office_reload: string;
  meeting_room_reload_cycle: number;
  workstation_reload_cycle: number;
  event_space_reload_cycle: number;
  private_office_reload_cycle: number;
  actual_price: number;
  lease_price: number;
};

export const CreateProductServicedOffice = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<ServicedOfficeType>();
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [optionsLocation, setOptionsLocation] = useState<DataOption[]>([]);
  const [optionsPromo, setOptionsPromo] = useState<DataOption[]>([]);
  const [locationForSearchSoList, setLocationForSearchSoList] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [actualPrice, setActualPrice] = useState<any>([]);
  const [leasePrice, setLeasePrice] = useState<any>([]);
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const arrProductName = getValues(["location_id", "serviced_office_id"]);
    axios
      .get(
        (process.env.REACT_APP_VOX_LOCATION as string) +
          `/${String(arrProductName[0])}`,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((response) => {
        const room =
          arrProductName[1] === undefined
            ? ""
            : arrProductName[1]
                ?.map((data: any) => {
                  return data.label;
                })
                .filter((v: any, i: any, a: any) => a.indexOf(v) === i)
                .join();
        const concat = response.data?.data?.name + "," + room;
        setValue("product_name", concat);
      })
      .catch((error) => {
        console.log(error);
      });
    setValue(
      "actual_price",
      actualPrice.reduce((a: any, b: any) => a + b, 0)
    );
    setValue(
      "lease_price",
      leasePrice.reduce((a: any, b: any) => a + b, 0)
    );
    setValue(
      "price",
      leasePrice.reduce((a: any, b: any) => a + b, 0)
    );
  }, [isUpdate]);

  const fetchData = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_VOX_SO_CLIENT as string) +
            `?filter[client_id]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.full_name} | ${element.company_name} | ${element.client_id}`,
                value: element.client_id,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const fetchDataSoList = (inputValue: any, callback: any) => {
    if (!inputValue) {
      callback([]);
    } else {
      setTimeout(() => {
        fetch(
          (process.env.REACT_APP_VOX_SO as string) +
            `?filter[location_id]=${locationForSearchSoList}&filter[room_name]=${inputValue}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const tempArray: any = [];
            res.data.forEach((element: any) => {
              tempArray.push({
                label: `${element.room_name}`,
                value: element.room_id,
                lease_price: element.lease_price,
                actual_price: element.actual_price,
              });
            });
            callback(tempArray);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const handleCreateProductServicedOffice: SubmitHandler<ServicedOfficeType> = (
    data
  ) => {
    setIsLoading(true);
    const postData = {
      product_name: data.product_name,
      client_id: data.client_id?.value,
      location_id: data.location_id,
      serviced_office_id: data.serviced_office_id?.map(
        (data: any) => data.value
      ),
      billing_cycle: data.billing_cycle,
      price: data.price,
      promo_id: data.promo_id ? data.promo_id : null,
      max_contacts: data.max_contacts,
      credit: data.credit,
      meeting_room_quota: data.meeting_room_quota,
      workstation_quota: data.workstation_quota,
      event_space_quota: data.event_space_quota,
      private_office_quota: data.private_office_quota,
      meeting_room_reload: "y",
      workstation_reload: "y",
      event_space_reload: "y",
      private_office_reload: "y",
      meeting_room_reload_cycle: 1,
      workstation_reload_cycle: 1,
      event_space_reload_cycle: 1,
      private_office_reload_cycle: 1,
    };
    axios
      .post(process.env.REACT_APP_VOX_SO_PRODUCT as string, postData, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        Swal.fire(
          "Product and Deal of Service Office has been created!",
          "",
          "success"
        );
        dispatch(setClientId(data.client_id?.value));
        navigate("/so-detail");
        // location.reload()
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_VOX_LOCATION as string, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptionsLocation((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_VOX_PROMO as string, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptionsPromo((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.promo_name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <p className="ml-10 mb-4 font-bold text-xl">
        Create Product Serviced Office
      </p>
      <div className="card w-auto bg-base-100 shadow-xl border border-slate-200">
        <div className="card-body">
          <form onSubmit={handleSubmit(handleCreateProductServicedOffice)}>
            <div className="form">
              <div className="flex flex-col md:flex-row">
                <div className="basis-1/2 mx-3">
                  <div>
                    <label className="label">
                      <span className="label-text">Client</span>
                    </label>
                    <Controller
                      control={control}
                      name="client_id"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <AsyncSelect
                          noOptionsMessage={() => "Type to search a client"}
                          value={value}
                          loadOptions={fetchData}
                          placeholder={`Client name`}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                    {errors.client_id ? (
                      <span className="text-sm text-red-700">
                        Field is required!
                      </span>
                    ) : null}
                  </div>
                  <div>
                    <label className="label">
                      <span className="label-text">Serviced Office List</span>
                    </label>
                    <Controller
                      control={control}
                      name="serviced_office_id"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <AsyncSelect
                          isMulti
                          noOptionsMessage={() => "Type to search a SO list"}
                          isDisabled={!locationForSearchSoList ? true : false}
                          value={value}
                          loadOptions={fetchDataSoList}
                          placeholder={`Serviced Office List`}
                          onChange={(e: any) => {
                            onChange(e);
                            setIsUpdate(!isUpdate);
                            setActualPrice(
                              e.map((data: any) => {
                                return data.actual_price;
                              })
                            );
                            setLeasePrice(
                              e.map((data: any) => {
                                return data.lease_price;
                              })
                            );
                          }}
                        />
                      )}
                    />
                    {errors.serviced_office_id ? (
                      <span className="text-sm text-red-700">
                        Field is required!
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col md:flex-row">
                    <div className="mr-0 md:mr-4">
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Actual Price</span>
                        </label>
                        <label className="input-group">
                          <span>IDR</span>
                          <input
                            type="text"
                            placeholder="0"
                            className="input input-bordered w-full"
                            readOnly={true}
                            {...register("actual_price")}
                          />
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">Lease Price</span>
                        </label>
                        <label className="input-group">
                          <span>IDR</span>
                          <input
                            type="text"
                            placeholder="0"
                            className="input input-bordered w-full"
                            readOnly={true}
                            {...register("lease_price")}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basis-1/2 mx-3">
                  <div>
                    <label className="label">
                      <span className="label-text">Location</span>
                    </label>
                    <Controller
                      control={control}
                      name="location_id"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <Select
                          isClearable
                          value={optionsLocation.find((c) => c.value === value)}
                          name={name}
                          options={optionsLocation}
                          onChange={(selectedOption: any) => {
                            onChange(selectedOption.value);
                            setLocationForSearchSoList(selectedOption.value);
                            setIsUpdate(!isUpdate);
                          }}
                        />
                      )}
                    />
                    {errors.location_id ? (
                      <span className="text-sm text-red-700">
                        Field is required!
                      </span>
                    ) : null}
                  </div>
                  <div>
                    <label className="label">
                      <span className="label-text">Product Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      readOnly={true}
                      className="input input-bordered w-full"
                      {...register("product_name")}
                    />
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="flex flex-col md:flex-row">
                <div className="basis-1/2 mx-3">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Billing Cycle</span>
                    </label>
                    <div>
                      <select
                        className={`${
                          !errors.billing_cycle?.type
                            ? "select select-bordered w-full"
                            : "select select-error w-full"
                        }`}
                        {...register("billing_cycle", { required: true })}
                      >
                        <option value="" selected>
                          Pick one
                        </option>
                        <option value="1">1 Month(s)</option>
                        <option value="3">3 Month(s)</option>
                        <option value="6">6 Month(s)</option>
                        <option value="12">12 Month(s)</option>
                      </select>
                    </div>
                    <span className="text-error text-sm font-bold">
                      {errors.billing_cycle?.type === "required" &&
                        "Field required"}
                    </span>
                  </div>
                </div>
                <div className="basis-1/2 mx-3">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Max Contact</span>
                    </label>
                    <label className="input-group">
                      <input
                        type="number"
                        placeholder="0"
                        className={`${
                          !errors.max_contacts?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...register("max_contacts", { required: true })}
                      />
                      <span>Person(s)</span>
                    </label>
                    <span className="text-error text-sm font-bold">
                      {errors.max_contacts?.type === "required" &&
                        "Field required"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="flex flex-col md:flex-row">
                <div className="basis-1/2 mx-3">
                  <label className="label">
                    <span className="label-text">Currency</span>
                  </label>
                  <input
                    type="text"
                    placeholder="IDR"
                    readOnly={true}
                    className="input input-bordered w-full"
                  />
                  <label className="label">
                    <span className="label-text">Credit</span>
                  </label>
                  <label className="input-group">
                    <span>IDR</span>
                    <input
                      type="number"
                      placeholder="0"
                      className={`${
                        !errors.credit?.type
                          ? `input input-bordered text-black w-full`
                          : `input border-2 border-error text-black w-full`
                      }`}
                      {...register("credit", { required: true })}
                    />
                  </label>
                  <span className="text-error text-sm font-bold">
                    {errors.credit?.type === "required" && "Field required"}
                  </span>
                </div>
                <div className="basis-1/2 mx-3">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Price</span>
                    </label>
                    <label className="input-group">
                      <span>IDR</span>
                      <input
                        type="number"
                        placeholder="0"
                        className={`${
                          !errors.price?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...register("price", { required: true })}
                      />
                    </label>
                    <span className="text-error text-sm font-bold">
                      {errors.price?.type === "required" && "Field required"}
                    </span>
                    <div>
                      <label className="label">
                        <span className="label-text">Promo</span>
                      </label>
                      <Controller
                        control={control}
                        name="promo_id"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            value={optionsPromo.find((c) => c.value === value)}
                            name={name}
                            options={optionsPromo}
                            onChange={(selectedOption: any) => {
                              onChange(selectedOption.value);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="flex flex-col md:flex-row">
                <div className="mx-3">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Meeting Room Quota</span>
                    </label>
                    <label className="input-group">
                      <input
                        type="number"
                        placeholder="0"
                        className={`${
                          !errors.meeting_room_quota?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...register("meeting_room_quota", { required: true })}
                      />
                      <span>Hour(s)</span>
                    </label>
                    <span className="text-error text-sm font-bold">
                      {errors.meeting_room_quota?.type === "required" &&
                        "Field required"}
                    </span>
                  </div>
                </div>
                <div className="mx-3">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Workstation Quota</span>
                    </label>
                    <label className="input-group">
                      <input
                        type="number"
                        placeholder="0"
                        className={`${
                          !errors.workstation_quota?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...register("workstation_quota", { required: true })}
                      />
                      <span>Hour(s)</span>
                    </label>
                    <span className="text-error text-sm font-bold">
                      {errors.workstation_quota?.type === "required" &&
                        "Field required"}
                    </span>
                  </div>
                </div>
                <div className="mx-3">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Event Space Quota</span>
                    </label>
                    <label className="input-group">
                      <input
                        type="number"
                        placeholder="0"
                        className={`${
                          !errors.event_space_quota?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...register("event_space_quota", { required: true })}
                      />
                      <span>Hour(s)</span>
                    </label>
                    <span className="text-error text-sm font-bold">
                      {errors.event_space_quota?.type === "required" &&
                        "Field required"}
                    </span>
                  </div>
                </div>
                <div className="mx-3">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Private Office Quota</span>
                    </label>
                    <label className="input-group">
                      <input
                        type="number"
                        placeholder="0"
                        className={`${
                          !errors.private_office_quota?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...register("private_office_quota", {
                          required: true,
                        })}
                      />
                      <span>Hour(s)</span>
                    </label>
                    <span className="text-error text-sm font-bold">
                      {errors.private_office_quota?.type === "required" &&
                        "Field required"}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="divider"></div>
                            <div className='flex flex-col md:flex-row justify-between'>
                                <div>
                                    <div className='mx-3'>
                                        <label className="label">
                                            <span className="label-text">Meeting Room Reload</span>
                                        </label>
                                        <div className='flex flex-col md:flex-row'>
                                            <div className="form-control">
                                                <label className="cursor-pointer flex items-center">
                                                    <input type="radio" className="radio checked:bg-red-500" value='y' defaultChecked {...register('meeting_room_reload')} />
                                                    <span className="label-text mx-2 my-2">Yes</span>
                                                </label>
                                            </div>
                                            <div className="form-control">
                                                <label className="cursor-pointer flex items-center">
                                                    <input type="radio" className="radio checked:bg-red-500" value='n' {...register('meeting_room_reload')} />
                                                    <span className="label-text mx-2 my-2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='mx-3'>
                                        <label className="label">
                                            <span className="label-text">Workstation Reload</span>
                                        </label>
                                        <div className='flex flex-col md:flex-row'>
                                            <div className="form-control">
                                                <label className="cursor-pointer flex items-center">
                                                    <input type="radio" className="radio checked:bg-red-500" value='y' defaultChecked {...register('workstation_reload')} />
                                                    <span className="label-text mx-2 my-2">Yes</span>
                                                </label>
                                            </div>
                                            <div className="form-control">
                                                <label className="cursor-pointer flex items-center">
                                                    <input type="radio" className="radio checked:bg-red-500" value='n' {...register('workstation_reload')} />
                                                    <span className="label-text mx-2 my-2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='mx-3'>
                                        <label className="label">
                                            <span className="label-text">Event Space Reload</span>
                                        </label>
                                        <div className='flex flex-col md:flex-row'>
                                            <div className="form-control">
                                                <label className="cursor-pointer flex items-center">
                                                    <input type="radio" className="radio checked:bg-red-500" value='y' defaultChecked {...register('event_space_reload')} />
                                                    <span className="label-text mx-2 my-2">Yes</span>
                                                </label>
                                            </div>
                                            <div className="form-control">
                                                <label className="cursor-pointer flex items-center">
                                                    <input type="radio" className="radio checked:bg-red-500" value='n' {...register('event_space_reload')} />
                                                    <span className="label-text mx-2 my-2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='mx-3'>
                                        <label className="label">
                                            <span className="label-text">Private Office Reload</span>
                                        </label>
                                        <div className='flex flex-col md:flex-row'>
                                            <div className="form-control">
                                                <label className="cursor-pointer flex items-center">
                                                    <input type="radio" className="radio checked:bg-red-500" value='y' defaultChecked {...register('private_office_reload')} />
                                                    <span className="label-text mx-2 my-2">Yes</span>
                                                </label>
                                            </div>
                                            <div className="form-control">
                                                <label className="cursor-pointer flex items-center">
                                                    <input type="radio" className="radio checked:bg-red-500" value='n' {...register('private_office_reload')} />
                                                    <span className="label-text mx-2 my-2">No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className='flex flex-col md:flex-row'>
                                <div className='mx-3'>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Meeting Room Reload Cycle</span>
                                        </label>
                                        <label className="input-group">
                                            <input type="number" placeholder="0" className={`${!errors.meeting_room_reload_cycle?.type ? `input input-bordered text-black w-full` : `input border-2 border-error text-black w-full`}`} {...register('meeting_room_reload_cycle', { required: true })} />
                                            <span>Month(s)</span>
                                        </label>
                                        <span className='text-error text-sm font-bold'>
                                            {errors.meeting_room_reload_cycle?.type === "required" && "Field required"}
                                        </span>
                                    </div>
                                </div>
                                <div className='mx-3'>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Workstation Reload Cycle</span>
                                        </label>
                                        <label className="input-group">
                                            <input type="number" placeholder="0" className={`${!errors.workstation_reload_cycle?.type ? `input input-bordered text-black w-full` : `input border-2 border-error text-black w-full`}`} {...register('workstation_reload_cycle', { required: true })} />
                                            <span>Month(s)</span>
                                        </label>
                                        <span className='text-error text-sm font-bold'>
                                            {errors.workstation_reload_cycle?.type === "required" && "Field required"}
                                        </span>
                                    </div>
                                </div>
                                <div className='mx-3'>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Event Space Reload Cycle</span>
                                        </label>
                                        <label className="input-group">
                                            <input type="number" placeholder="0" className={`${!errors.event_space_reload_cycle?.type ? `input input-bordered text-black w-full` : `input border-2 border-error text-black w-full`}`} {...register('event_space_reload_cycle', { required: true })} />
                                            <span>Month(s)</span>
                                        </label>
                                        <span className='text-error text-sm font-bold'>
                                            {errors.event_space_reload_cycle?.type === "required" && "Field required"}
                                        </span>
                                    </div>
                                </div>
                                <div className='mx-3'>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Private Office Reload Cycle</span>
                                        </label>
                                        <label className="input-group">
                                            <input type="number" placeholder="0" className={`${!errors.private_office_reload_cycle?.type ? `input input-bordered text-black w-full` : `input border-2 border-error text-black w-full`}`} {...register('private_office_reload_cycle', { required: true })} />
                                            <span>Month(s)</span>
                                        </label>
                                        <span className='text-error text-sm font-bold'>
                                            {errors.private_office_reload_cycle?.type === "required" && "Field required"}
                                        </span>
                                    </div>
                                </div>
                            </div> */}
            </div>
            <button
              className={
                IsLoading
                  ? "btn loaading w-full mt-4"
                  : "btn bg-pink-700 border-0 w-full mt-4"
              }
              type="submit"
            >
              Create
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
