import React, { useEffect, useState } from 'react'
import { rupiah } from '../../../../helper/rupiah'
import Modal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { InvoiceTabsLoading } from './InvoiceTabsLoading/InvoiceTabsLoading';
import { InvoiceDetailLoading } from './InvoiceDetailLoading/InvoiceDetailLoading';
Modal.setAppElement("#root");

type propsType = {
    data?: any
    clientName?: string
    email?: string
}

type InvoiceDetailDataType = {
    invoice_no: number
    status_invoice: string
    period_from: string
    period_to: string
    extend_period: string
    location: string
    amount_due: number
    discount_percentage: number
    discount: number
    vat: number
    vat_price: number
    pph23: number
    pph42: number
}

export const InvoiceTabs = ({ data, clientName, email }: propsType) => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });
    const [dataTable, setDataTable] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModalViewProforma, setIsOpenModalViewProforma] = useState(false);
    const [isOpenModalOptionViewProforma, setIsOpenModalOptionViewProforma] = useState(false);
    const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetailDataType>();
    const [detailItems, setDetailItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfProformaInvoice, setPdfProformaInvoice] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)
        axios.get(
            (process.env.REACT_APP_VOX_TABS_DETAIL as string) + `/${data}/invoices`,
            {
                headers: {
                    "Authorization": `Bearer ${globalStateUser.token}`
                }
            }
        )
            .then(res => {
                setIsLoading(false)
                setDataTable(res?.data?.data)
            })
            .catch(error => {
                setIsLoading(true);
                console.log(error)
            })
    }, []);

    function toggleModal(data?: any) {
        setIsOpen(!isOpen);
        setInvoiceDetail({
            ...invoiceDetail,
            invoice_no: data.id,
            status_invoice: data.status_invoice,
            period_from: data.period_from,
            period_to: data.period_to,
            extend_period: data?.extend_period,
            location: data.location?.name,
            amount_due: data.final_result?.grand_total,
            discount_percentage: data.final_result?.discount?.label,
            discount: data.final_result?.discount?.nominal,
            vat: data.final_result?.vat?.percentage * 100,
            vat_price: data.final_result?.vat?.nominal,
            pph23: data.final_result?.pph23,
            pph42: data.final_result?.pph42,
        })
        setDetailItems(data?.final_result?.products)
    }

    function toggleModalViewProforma(data?: any) {
        setIsOpenModalViewProforma(!isOpenModalViewProforma);
        if (!isOpenModalViewProforma) {
            axios.get(
                (process.env.REACT_APP_VOX_PREVIEW_PROFORMA_INVOICE as string) + `${data}/check`,
                {
                    headers: {
                        "Authorization": `Bearer ${globalStateUser.token}`
                    }
                }
            )
                .then(res => {
                    if (res.data.message === 'exist') {
                        setPdfProformaInvoice(res.data?.data?.url)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `${res.data.message}`
                        })
                        setIsOpenModalViewProforma(false);
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!'
                    })
                })
        }
    }

    function toggleModalOptionViewProforma(data?: any) {
        setIsOpenModalOptionViewProforma(!isOpenModalOptionViewProforma);
    }

    const handleSendInvoice = (invoiceId: any) => {
        setIsLoading(true)
        const dataPost = {
            invoice_id: invoiceId
        }
        axios.post(
            (process.env.REACT_APP_VOX_SEND_PROFORMA_INVOICE as string),
            dataPost,
            {
                headers: {
                    "Authorization": `Bearer ${globalStateUser.token}`
                }
            }
        )
            .then(res => {
                Swal.fire('Invoice Send!', '', 'success')
                setIsLoading(false)
                // navigate('/vo-data')
            })
            .catch(error => {
                setIsLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })

            })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal w-11/12 lg:w-8/12 overflow-y-auto max-h-full"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
                shouldCloseOnEsc={false}
            >
                {/* {
                    isLoading ?
                        <InvoiceDetailLoading />
                        : */}
                <div className='p-4'>
                    <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={toggleModal}>✕</label>
                    <h3 className='text-xl font-bold'>Invoice Number {invoiceDetail?.invoice_no}</h3>
                    <div className="divider"></div>
                    {
                        invoiceDetail?.status_invoice === "unpaid" ?
                            <button className={isLoading ? 'btn btn-sm loading' : 'btn btn-sm bg-pink-700 border-0'} onClick={toggleModalOptionViewProforma}>{isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}&nbsp;&nbsp;Proforma Invoice</button>
                            :
                            <button className={isLoading ? 'btn btn-sm loading' : 'btn btn-sm bg-pink-700 border-0'} onClick={() => toggleModalViewProforma(invoiceDetail?.invoice_no)}><FontAwesomeIcon icon={faFile} />&nbsp;&nbsp;View Proforma Invoice</button>
                    }
                    <div className="divider"></div>
                    <div className='flex flex-row justify-evenly flex-wrap'>
                        <div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>Status</p>
                                <p className='font-light text-center'>{invoiceDetail?.status_invoice}</p>
                            </div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>Email</p>
                                <p className='font-light text-center'>{email}</p>
                            </div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>Amount Paid</p>
                                <p className='font-light text-center'>{rupiah(invoiceDetail?.amount_due)}</p>
                            </div>
                        </div>
                        <div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>Period From</p>
                                <p className='font-light text-center'>{invoiceDetail?.period_from}</p>
                            </div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>Location Name</p>
                                <p className='font-light text-center'>{invoiceDetail?.location}</p>
                            </div>
                            {
                                invoiceDetail?.discount ?
                                    <>
                                        <div className='mb-4'>
                                            <p className='font-bold text-center'>Discount Price</p>
                                            <p className='font-light text-center'>{rupiah(invoiceDetail?.discount)}</p>
                                        </div>
                                    </>
                                    :
                                    null
                            }
                            {/* {
                                invoiceDetail?.discount ?
                                    <>
                                        <div className='mb-4'>
                                            <p className='font-bold text-center'>Discount</p>
                                            <p className='font-light text-center'>{invoiceDetail?.discount_percentage}</p>
                                        </div>
                                    </>
                                    :
                                    null
                            } */}
                        </div>
                        <div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>Period To</p>
                                <p className='font-light text-center'>{invoiceDetail?.period_to}</p>
                            </div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>PPH 23</p>
                                <p className='font-light text-center'>{rupiah(invoiceDetail?.pph23)}</p>
                            </div>
                        </div>
                        <div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>Extend Period</p>
                                <p className='font-light text-center'>{invoiceDetail?.extend_period}</p>
                            </div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>PPH 4(2)</p>
                                <p className='font-light text-center'>{rupiah(invoiceDetail?.pph42)}</p>
                            </div>
                            {/* <div className='mb-4'>
                                <p className='font-bold text-center'>VAT</p>
                                <p className='font-light text-center'>{invoiceDetail?.vat} %</p>
                            </div> */}
                        </div>
                        <div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>Client Name</p>
                                <p className='font-light text-center'>{clientName}</p>
                            </div>
                            <div className='mb-4'>
                                <p className='font-bold text-center'>VAT Nominal</p>
                                <p className='font-light text-center'>{rupiah(invoiceDetail?.vat_price)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <p>Items : </p>
                    <div>
                        <div className="overflow-x-auto border border-slate-200 rounded-md">
                            <table className="table w-full">
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Product</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        detailItems?.map((data: any, idx: any) => {
                                            return <tr key={idx}>
                                                <th />
                                                <td>{data.product_name}</td>
                                                <td>{rupiah(data.product_price)}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* } */}
            </Modal>
            <Modal
                isOpen={isOpenModalOptionViewProforma}
                onRequestClose={toggleModalOptionViewProforma}
                contentLabel="My dialog"
                className="mymodal w-11/12 lg:w-5/12 lg:h-6/6 overflow-y-auto max-h-full"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
                shouldCloseOnEsc={false}
            >
                <div>
                    <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={toggleModalOptionViewProforma}>✕</label>
                    <div>
                        <h3 className='font-bold text-center'>Proforma Invoice</h3>
                        <div className='flex flex-col md:flex-row items-center justify-center gap-2 mt-3'>
                            <div>
                                <button className={isLoading ? 'btn btn-sm loading' : 'btn btn-sm bg-pink-700 border-0'} onClick={() => handleSendInvoice(invoiceDetail?.invoice_no)}>{isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}&nbsp;&nbsp;Send Proforma Invoice</button>
                            </div>
                            <div>
                                <button className={isLoading ? 'btn btn-sm loading' : 'btn btn-sm bg-pink-700 border-0'} onClick={() => toggleModalViewProforma(invoiceDetail?.invoice_no)}><FontAwesomeIcon icon={faFile} />&nbsp;&nbsp;View Proforma Invoice</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isOpenModalViewProforma}
                onRequestClose={toggleModalViewProforma}
                contentLabel="My dialog"
                className="mymodal w-11/12 lg:w-10/12 lg:h-6/6 overflow-y-auto max-h-full"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
                shouldCloseOnEsc={false}
            >
                <div>
                    <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={toggleModalViewProforma}>✕</label>
                    <iframe src={pdfProformaInvoice}></iframe>
                </div>
            </Modal>
            {
                isLoading ?
                    <div>
                        <div className='mt-2'>
                            <p className='text-sm italic'><FontAwesomeIcon icon={faCircleExclamation} />&nbsp;Mohon menunggu beberapa detik untuk load data</p>
                            <div className='mt-2'>
                                <InvoiceTabsLoading />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="overflow-x-auto border border-slate-200 rounded-md">
                        <table className="table w-full">
                            {/* head */}
                            <thead>
                                <tr>
                                    <th />
                                    <th>Invoice ID</th>
                                    <th>Type</th>
                                    <th>Location</th>
                                    <th>Due Date</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataTable?.map((data: any, idx: any) => {
                                        return <tr key={idx} className="hover cursor-pointer" onClick={() => toggleModal(data)}>
                                            <th />
                                            <td>{data?.id}</td>
                                            <td>{data?.final_result?.invoice_type === "vo" ? "Virtual Office" : data?.final_result?.invoice_type === "so" ? "Serviced Office" : "-"}</td>
                                            <td>{data?.location?.name}</td>
                                            <td>{data?.date_due}</td>
                                            <td>{rupiah(data?.final_result?.grand_total)}</td>
                                            <td>{data?.status_invoice}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
            }
        </>
    )
}
