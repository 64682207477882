/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import axios from "axios";
import Swal from "sweetalert2";

export interface DataOption {
  value: string;
  label: string;
}

type ServicedOfficeType = {
  room_name: string;
  location_id: any;
  room_capacity: number;
  room_size: number;
  lease_price: number;
  offer_price: number;
  actual_price: number;
  view: string;
};

export const CreateServicedOffice = () => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const [optionsLocation, setOptionsLocation] = useState<DataOption[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<ServicedOfficeType>();
  const [IsLoading, setIsLoading] = useState(false);

  const handleCreateServicedOffice: SubmitHandler<ServicedOfficeType> = (
    data
  ) => {
    setIsLoading(true);
    axios
      .post(process.env.REACT_APP_VOX_SO as string, data, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        Swal.fire("Serviced Office Added!", "", "success");
        location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_VOX_LOCATION as string, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((response) => {
        response.data?.data?.forEach((data: any) => {
          setOptionsLocation((prevState) => [
            ...prevState,
            {
              value: data.id,
              label: data.name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <p className="ml-10 mb-4 font-bold text-xl">Create Room Location</p>
      <div className="card w-auto bg-base-100 shadow-xl border border-slate-200">
        <div className="card-body">
          <div className="form-control">
            <form onSubmit={handleSubmit(handleCreateServicedOffice)}>
              <div className="flex flex-col md:flex-row">
                <div className="basis-1/2 mx-3">
                  <label className="label">
                    <span className="label-text">Room Name</span>
                  </label>
                  <input
                    type="text"
                    placeholder="room name"
                    className={`${
                      !errors.room_name?.type
                        ? `input input-bordered text-black w-full`
                        : `input border-2 border-error text-black w-full`
                    }`}
                    {...register("room_name", { required: true })}
                  />
                  <span className="text-error text-sm font-bold">
                    {errors.room_name?.type === "required" && "Field required"}
                  </span>
                  <label className="label">
                    <span className="label-text">Room Capacity (Pax)</span>
                  </label>
                  <input
                    type="number"
                    placeholder="room capacity"
                    className={`${
                      !errors.room_capacity?.type
                        ? `input input-bordered text-black w-full`
                        : `input border-2 border-error text-black w-full`
                    }`}
                    {...register("room_capacity", { required: true })}
                  />
                  <span className="text-error text-sm font-bold">
                    {errors.room_capacity?.type === "required" &&
                      "Field required"}
                  </span>
                </div>
                <div className="basis-1/2 mx-3">
                  <label className="label">
                    <span className="label-text">Location</span>
                  </label>
                  <Controller
                    control={control}
                    name="location_id"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        isClearable
                        value={optionsLocation.find((c) => c.value === value)}
                        name={name}
                        options={optionsLocation}
                        onChange={(selectedOption: any) => {
                          onChange(selectedOption.value);
                        }}
                      />
                    )}
                  />
                  {errors.location_id ? (
                    <span className="text-sm text-red-700">
                      Location is required!
                    </span>
                  ) : null}
                  <label className="label md:mt-2">
                    <span className="label-text">
                      Room Size (m<sup>2</sup>)
                    </span>
                  </label>
                  <input
                    type="number"
                    placeholder="room size"
                    className={`${
                      !errors.room_size?.type
                        ? `input input-bordered text-black w-full`
                        : `input border-2 border-error text-black w-full`
                    }`}
                    {...register("room_size", { required: true })}
                  />
                  <span className="text-error text-sm font-bold">
                    {errors.room_size?.type === "required" && "Field required"}
                  </span>
                </div>
              </div>
              <div className="divider"></div>
              <div className="flex flex-col md:flex-row">
                <div className="basis-1/3 mx-3">
                  <label className="label">
                    <span className="label-text">Lease Price</span>
                  </label>
                  <input
                    type="number"
                    placeholder="lease price"
                    className={`${
                      !errors.lease_price?.type
                        ? `input input-bordered text-black w-full`
                        : `input border-2 border-error text-black w-full`
                    }`}
                    {...register("lease_price", { required: true })}
                  />
                  <span className="text-error text-sm font-bold">
                    {errors.lease_price?.type === "required" &&
                      "Field required"}
                  </span>
                </div>
                <div className="basis-1/3 mx-3">
                  <label className="label">
                    <span className="label-text">Offer Price</span>
                  </label>
                  <input
                    type="number"
                    placeholder="offer price"
                    className={`${
                      !errors.offer_price?.type
                        ? `input input-bordered text-black w-full`
                        : `input border-2 border-error text-black w-full`
                    }`}
                    {...register("offer_price", { required: true })}
                  />
                  <span className="text-error text-sm font-bold">
                    {errors.offer_price?.type === "required" &&
                      "Field required"}
                  </span>
                </div>
                <div className="basis-1/3 mx-3">
                  <label className="label">
                    <span className="label-text">Actual Price</span>
                  </label>
                  <input
                    type="number"
                    placeholder="actual price"
                    className={`${
                      !errors.actual_price?.type
                        ? `input input-bordered text-black w-full`
                        : `input border-2 border-error text-black w-full`
                    }`}
                    {...register("actual_price", { required: true })}
                  />
                  <span className="text-error text-sm font-bold">
                    {errors.actual_price?.type === "required" &&
                      "Field required"}
                  </span>
                </div>
              </div>
              <div>
                <div className="mx-3">
                  <label className="label">
                    <span className="label-text">View</span>
                  </label>
                  <div className="flex flex-col md:flex-row">
                    <div className="form-control">
                      <label className="cursor-pointer flex items-center">
                        <input
                          type="radio"
                          className="radio checked:bg-red-500"
                          value="y"
                          defaultChecked
                          {...register("view", { required: true })}
                        />
                        <span className="label-text mx-2 my-2">Yes</span>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="cursor-pointer flex items-center">
                        <input
                          type="radio"
                          className="radio checked:bg-red-500"
                          value="n"
                          {...register("view", { required: true })}
                        />
                        <span className="label-text mx-2 my-2">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className={
                  IsLoading
                    ? "btn loading w-full mt-4"
                    : "btn bg-pink-700 border-0 text-white w-full mt-4"
                }
                type="submit"
              >
                Create
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
