import React from 'react'

export const DealInProgressDetailLoading = () => {
    return (
        <div>
            <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
            <h3 className='text-xl font-bold'>Deal In Progress</h3>
            <div className="divider"></div>
            <div className="animate-pulse flex space-x-4 w-1/3">
                <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                        <div className="h-7 bg-slate-700 rounded" />
                    </div>
                </div>
            </div>
            <div className="divider"></div>
            <div>
                <p className='font-bold text-lg mb-3'>Follow Up</p>
                <div className="overflow-x-auto border border-slate-200 rounded-md">
                    <table className="table w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                <th />
                                <th>Date & Time</th>
                                <th>Result</th>
                                <th>Action</th>
                                <th>Operator</th>
                            </tr>
                        </thead>
                        <tbody>
                            <td />
                            <td>
                                <div className="animate-pulse flex space-x-4 w-16">
                                    <div className="flex-1 space-y-6 py-1">
                                        <div className="space-y-3">
                                            <div className="h-2 bg-slate-700 rounded" />
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="animate-pulse flex space-x-4 w-16">
                                    <div className="flex-1 space-y-6 py-1">
                                        <div className="space-y-3">
                                            <div className="h-2 bg-slate-700 rounded" />
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="animate-pulse flex space-x-4 w-16">
                                    <div className="flex-1 space-y-6 py-1">
                                        <div className="space-y-3">
                                            <div className="h-2 bg-slate-700 rounded" />
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="animate-pulse flex space-x-4 w-16">
                                    <div className="flex-1 space-y-6 py-1">
                                        <div className="space-y-3">
                                            <div className="h-2 bg-slate-700 rounded" />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tbody>
                    </table>
                </div>
                <div className="animate-pulse flex space-x-4 w-36">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="h-7 bg-slate-700 rounded" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider"></div>
            <p className='font-bold text-lg mb-3'>Products</p>
            <div className="overflow-x-auto border border-slate-200 rounded-md">
                <table className="table w-full">
                    {/* head */}
                    <thead>
                        <tr>
                            <th />
                            <th>Product Name</th>
                            <th>Product Category</th>
                            <th>Location</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td />
                        <td>
                            <div className="animate-pulse flex space-x-4 w-16">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-16">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-16">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-16">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tbody>
                </table>
            </div>
            <div className="divider"></div>
            <p className='font-bold text-lg mb-3'>Promo</p>
            <div>
                <div className="animate-pulse flex space-x-4 w-full">
                    <div className="flex-1 space-y-6 py-1">
                        <div className="space-y-3">
                            <div className="h-16 bg-slate-700 rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
