import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { MailHandlingContactTabsLoading } from "./MailHandlingContactTabsLoading/MailHandlingContactTabsLoading";

type propsType = {
  data?: any;
};

export const MailHandlingContactTabs = ({ data }: propsType) => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_VOX_TABS_DETAIL as string) +
          `/${data}/mail-handling-contact`,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setDataTable(res.data.data);
      })
      .catch((error) => {
        setIsLoading(true);
        console.log(error);
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <MailHandlingContactTabsLoading />
      ) : (
        <div className="overflow-x-auto border border-slate-200 rounded-md">
          <table className="table w-full">
            {/* head */}
            <thead>
              <tr>
                <th />
                <th>Id</th>
                <th>Email</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {dataTable.length === 0 ? (
                <tr>
                  <th></th>
                  <td>No Data</td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                dataTable.map((data: any, idx: any) => {
                  return (
                    <tr key={idx}>
                      <th />
                      <td>{data?.id}</td>
                      <td>{data?.email}</td>
                      <td>{data?.phone_number}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
