/* eslint-disable no-restricted-globals */
import axios from 'axios'
import React from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { RootState } from '../../../../../store/store'

type propsType = {
    data?: any[]
}

export const CancelationTable = ({ data }: propsType) => {
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });

    const handleCancel = (data: any) => {
        const dataPutCancel = {
            invoice_id: data
        }
        Swal.fire({
            title: 'Do you want to proceed cancelation?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Please wait')
                Swal.showLoading()
                axios.put(
                    (process.env.REACT_APP_CANCELATION_PROCESS as string),
                    dataPutCancel,
                    {
                        headers: {
                            "Authorization": `Bearer ${globalStateUser.token}`
                        }
                    }
                )
                    .then(res => {
                        Swal.fire('Saved!', '', 'success')
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    return (
        <div className="overflow-x-auto">
            <table className="table-auto w-full">
                {/* head */}
                <thead className='border border-slate-300'>
                    <tr>
                        {/* <th className='bg-pink-700' /> */}
                        <th className='bg-gray-100 text-gray-600 full-width'>Invoice ID</th>
                        <th className='bg-gray-100 text-gray-600 max-w-md text-left full-width'>Name</th>
                        <th className='bg-gray-100 text-gray-600 text-left full-width'>Company</th>
                        <th className='bg-gray-100 text-gray-600 text-left full-width'>Location</th>
                        <th className='bg-gray-100 text-gray-600 '>Plan</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((data, idx) => {
                            return (
                                <>
                                    <tr className="cursor-pointer text-sm hover:bg-slate-100 border-x-2 border-b-2" key={idx} onClick={() => handleCancel(data?.id)} >
                                        {/* <th /> */}
                                        <td className='text-center'>{data?.id}</td>
                                        <td className='text-left'>{data?.client?.full_name}</td>
                                        <td className='text-left'>{data?.client?.company_name}</td>
                                        <td className='text-left'>{data?.location?.name}</td>
                                        <td className='text-center'>{data?.details.map((data: any, idx: any) => {
                                            return data.product_name
                                        })}</td>
                                    </tr>
                                </>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
