/* eslint-disable no-restricted-globals */
import {
  faCircleExclamation,
  faEnvelope,
  faFile,
  faFileCircleCheck,
  faFileLines,
  faPaperPlane,
  faSquarePhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { RootState } from "../../../../../store/store";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { rupiah } from "../../../../../helper/rupiah";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DetailModalLoading } from "./DetailModalLoading/DetailModalLoading";
import { faFileArchive } from "@fortawesome/free-regular-svg-icons";
import Select from "react-select";
Modal.setAppElement("#root");

export interface DataOption {
  value: string;
  label: string;
}

type propsType = {
  data?: any[];
};

type InvoiceDetailDataType = {
  invoice_no?: number;
  invoice_data_message?: string;
  invoice_activation_status?: string;
  details?: [];
  location_id?: number;
  client_id?: number;
};

type Inputs = {
  invoice_id?: number;
  did_no?: string;
  location_bank_account_id?: string;
  security_deposit?: number;
  start_date?: string;
  end_date?: string;
  extend_period?: string;
  additional_meeting_room_quota?: number;
  additional_event_space_quota?: number;
  additional_workstation_quota?: number;
  additional_private_office_quota?: number;
};

type ActivatePlan = {
  invoice_id: number;
  id_card_no: string;
  // id_card_file: any;
  // company_reg_file: string;
  tax_id_no: string;
  // tax_id_file: string;
  company_tax_id_no: string;
  // company_tax_id_file: string;
  // fax_number: string;
  did_no: string;
  address_npwp_company: string;
  address_ktp: string;
  type: number;
  company_name?: string;
};

export const ActivationTable = ({ data }: propsType) => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const {
    register: registerGA,
    handleSubmit: handleSubmitGA,
    reset: resetGA,
    control: controlGA,
    formState: { errors: errorsGA },
    setValue: setValueGa,
  } = useForm<Inputs>({
    defaultValues: {
      additional_event_space_quota: 0,
      additional_meeting_room_quota: 0,
      additional_private_office_quota: 0,
      additional_workstation_quota: 0,
    },
  });
  const {
    register: registerAP,
    watch: watchAP,
    handleSubmit: handleSubmitAP,
    reset: resetAP,
    control: controlAP,
    formState: { errors: errorsAP },
    setValue: setValueAP,
  } = useForm<ActivatePlan>();
  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetailDataType>();
  const [dataForm, setDataForm] = useState({
    clientName: "",
    companyName: "",
  });
  const [pdfOfficialInvoice, setPdfOfficialInvoice] = useState("");
  const [pdfAgreement, setPdfAgreement] = useState("");
  const [pdfPerjakbi, setPdfPerjakbi] = useState("");
  const [optionsLocationBankAccount, setOptionsLocationBankAccount] = useState<
    DataOption[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [placeHolders, setPlaceHolders] = useState({
    ktpName: "",
    npwpPribadiName: "",
    tdpName: "",
    npwpPerusahaanName: "",
  });
  const watchType: any = watchAP("type");

  const [isOpen, setIsOpen] = useState(false);
  function toggleModal(data?: any) {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setIsLoading(true);
      setDataForm({
        ...dataForm,
        clientName: data.client.full_name,
        companyName: data.client.company_name,
      });
      axios
        .get(
          (process.env.REACT_APP_VOX_ACTIVATION_DATA as string) + `${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((response) => {
          setInvoiceDetail({
            ...invoiceDetail,
            invoice_no: data.id,
            invoice_data_message: response.data?.message,
            invoice_activation_status: response.data?.data?.status_activation,
            details: data.details,
            location_id: response.data?.data?.invoice?.location_id,
            client_id: response.data?.data?.invoice?.client_id,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const [isOpenGenerateAgreement, setIsOpenGenerateAgreement] = useState(false);
  function toggleModalGenerateAgreement(data?: any) {
    setIsOpenGenerateAgreement(!isOpenGenerateAgreement);
    if (!isOpenGenerateAgreement) {
      setIsLoading(true);
      axios
        .get((process.env.REACT_APP_VOX_INVOICE as string) + data, {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setValueGa("did_no", response.data?.data?.activation_data?.did_no);
          setValueGa(
            "extend_period",
            response.data?.data?.activation_data?.extend_period &&
              response.data?.data?.activation_data?.extend_period
          );
          setValueGa(
            "location_bank_account_id",
            response.data?.data?.activation_data?.bank?.id
          );
          setValueGa(
            "security_deposit",
            response.data?.data?.activation_data?.security_deposit
          );
          setValueGa("start_date", response.data?.data?.period_from);
          setValueGa("end_date", response.data?.data?.period_to);
          setValueGa(
            "additional_event_space_quota",
            response.data?.data?.details[0]?.plan?.additional_hour?.event_space
          );
          setValueGa(
            "additional_private_office_quota",
            response.data?.data?.details[0]?.plan?.additional_hour
              ?.private_office_quota
          );
          setValueGa(
            "additional_meeting_room_quota",
            response.data?.data?.details[0]?.plan?.additional_hour
              ?.meeting_room_quota
          );
          setValueGa(
            "additional_workstation_quota",
            response.data?.data?.details[0]?.plan?.additional_hour
              ?.workspace_quota
          );
          response.data?.data?.location?.banks?.forEach((data: any) => {
            setOptionsLocationBankAccount((prevState) => [
              ...prevState,
              {
                value: data.id,
                label: data.bank_name,
              },
            ]);
          });
        })
        .catch((error) => {
          setIsLoading(true);
          console.log(error);
        });
    } else {
      setOptionsLocationBankAccount([]);
      resetGA();
    }
  }
  const [isOpenOptionOfficialInvoice, setIsOpenOptionOfficialInvoice] =
    useState(false);
  function toggleModalOptionOfficialInvoice(data?: any) {
    setIsOpenOptionOfficialInvoice(!isOpenOptionOfficialInvoice);
  }
  const [isOpenOptionPerjakbi, setIsOpenOptionPerjakbi] = useState(false);
  function toggleModalOptionPerjakbi(data?: any) {
    setIsOpenOptionPerjakbi(!isOpenOptionPerjakbi);
  }
  const [isOpenOptionDraftAgreement, setIsOpenOptionDraftAgreement] =
    useState(false);
  function toggleModalOptionDraftAgreement(data?: any) {
    setIsOpenOptionDraftAgreement(!isOpenOptionDraftAgreement);
  }
  const [isOpenOptionOfficialAgreement, setIsOpenOptionOfficialAgreement] =
    useState(false);
  function toggleModalOptionOfficialAgreement(data?: any) {
    setIsOpenOptionOfficialAgreement(!isOpenOptionOfficialAgreement);
  }
  const [isOpenViewAgreement, setIsOpenViewAgreement] = useState(false);
  function toggleModalViewAgreement(data?: any) {
    setIsOpenViewAgreement(!isOpenViewAgreement);
    const url =
      location.pathname === "/vo-activation"
        ? (process.env.REACT_APP_VOX_PREVIEW_AGREEMENT_VO as string) +
          `${data}/check`
        : (process.env.REACT_APP_VOX_PREVIEW_AGREEMENT_SO as string) +
          `${data}/check`;
    if (!isOpenViewAgreement) {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        })
        .then((res) => {
          if (res.data.message === "exist") {
            setPdfAgreement(res.data?.data?.url);
          } else if (res.data.message === "draft") {
            setPdfAgreement(res.data?.data?.url);
          } else {
            Swal.fire({
              title: "Agreement is being generated!",
              timer: 20000,
            });
            Swal.showLoading();
            setIsOpenViewAgreement(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  }
  const [isOpenPerjakbi, setIsOpenPerjakbi] = useState(false);
  function toggleModalPerjakbi(data?: any) {
    setIsOpenPerjakbi(!isOpenPerjakbi);
    if (!isOpenPerjakbi) {
      axios
        .get(
          (process.env.REACT_APP_VOX_PREVIEW_PERJAKBI as string) +
            `${data.invoice_no}/${data.location_id}/${data.client_id}/check`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.message === "exist") {
            setPdfPerjakbi(res.data?.data?.url);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${res.data.message}`,
            });
            setIsOpenPerjakbi(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  }
  const [isOpenModalActivatePlan, setIsOpenModalActivatePlan] = useState(false);
  function toggleModalActivatePlan(data?: any) {
    setIsOpenModalActivatePlan(!isOpenModalActivatePlan);
    if (!isOpenModalActivatePlan) {
      setIsLoading(true);
      axios
        .get(
          (process.env.REACT_APP_VOX_ACTIVATION_DATA as string) + `${data}`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((response) => {
          setValueAP("id_card_no", response.data?.data?.id_card_no);
          setValueAP(
            "company_tax_id_no",
            response.data?.data?.company_tax_id_no
          );
          setValueAP("tax_id_no", response.data?.data?.tax_id_no);
          // setValueAP("fax_number", response.data?.data?.fax_number);
          setValueAP(
            "address_npwp_company",
            response.data?.data?.address_npwp_company
          );
          setValueAP("address_ktp", response.data?.data?.address_ktp);
          setPlaceHolders({
            ...placeHolders,
            ktpName: response.data?.data?.id_card_file,
            npwpPribadiName: response.data?.data?.tax_id_file,
            npwpPerusahaanName: response.data?.data?.company_tax_id_file,
          });
          // setValueAP(
          //   "id_card_file",
          //   response.data?.data?.base64_image?.id_card
          // );
          // setValueAP("tax_id_file", response.data?.data?.base64_image?.tax_id);
          // setValueAP(
          //   "company_tax_id_file",
          //   response.data?.data?.base64_image?.company_tax_id
          // );
          setValueAP("did_no", response.data?.data?.did_no);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    } else {
      resetAP();
      setPlaceHolders({
        ...placeHolders,
        ktpName: "",
        npwpPribadiName: "",
        npwpPerusahaanName: "",
      });
    }
  }

  const [isOpenModalOfficialInvoice, setIsOpenModalOfficialInvoice] =
    useState(false);
  function toggleModalOfficialInvoice(data?: any) {
    setIsOpenModalOfficialInvoice(!isOpenModalOfficialInvoice);
    if (!isOpenModalOfficialInvoice) {
      axios
        .get(
          (process.env.REACT_APP_VOX_PREVIEW_OFFICIAL_INVOICE as string) +
            `${data}/check`,
          {
            headers: {
              Authorization: `Bearer ${globalStateUser.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.message === "exist") {
            setPdfOfficialInvoice(res.data?.data?.url);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${res.data.message}`,
            });
            setIsOpenModalOfficialInvoice(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  }

  const handleGenerateAgreement: SubmitHandler<Inputs> = (data) => {
    setIsLoading(true);
    const dataGenerateAggrement = {
      invoice_id: invoiceDetail?.invoice_no,
      did_no: data.did_no,
      location_bank_account_id: Number(data.location_bank_account_id),
      security_deposit: Number(data.security_deposit),
      start_date: data.start_date,
      end_date: data.end_date,
      extend_period: data.extend_period ? data.extend_period : 0,
      additional_meeting_room_quota: Number(data.additional_meeting_room_quota),
      additional_workstation_quota: Number(data.additional_workstation_quota),
      additional_event_space_quota: Number(data.additional_event_space_quota),
      additional_private_office_quota: Number(
        data.additional_private_office_quota
      ),
    };
    axios
      .post(
        process.env.REACT_APP_VOX_GENERATE_AGREEMENT as string,
        dataGenerateAggrement,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire("Agrement Generated!", "", "success");
        setIsLoading(false);
        setIsOpenGenerateAgreement(false);
        setIsOpen(false);
        // location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleActivatePlan: SubmitHandler<ActivatePlan> = (data) => {
    setIsLoading(true);
    const dataHandleActivePlan = {
      invoice_id: invoiceDetail?.invoice_no,
      id_card_no: data.id_card_no,
      // id_card_file: data.id_card_file,
      // company_reg_file: data.company_reg_file,
      tax_id_no: data.tax_id_no,
      // tax_id_file: data.tax_id_file,
      company_tax_id_no: data.company_tax_id_no,
      // company_tax_id_file: data.company_tax_id_file,
      // fax_number: data.fax_number,
      did_no: data.did_no,
      address_npwp_company: data.address_npwp_company,
      address_ktp: data.address_ktp,
      type: Number(data.type),
      company_name: data.company_name,
    };
    console.log(JSON.stringify(dataHandleActivePlan));
    axios
      .post(
        process.env.REACT_APP_VOX_VO_ACTIVATION as string,
        dataHandleActivePlan,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire("Invoice Activated!", "", "success");
        setIsLoading(false);
        location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleSendOfficialInvoice = (invoiceId: any) => {
    setIsLoading(true);
    const dataPost = {
      invoice_id: invoiceId,
    };
    axios
      .post(
        process.env.REACT_APP_VOX_SEND_OFFICIAL_INVOICE as string,
        dataPost,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire("Invoice Send!", "", "success");
        setIsLoading(false);
        // navigate('/vo-data')
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleSendPerjakbi = (invoiceId: any) => {
    setIsLoading(true);
    const dataPost = {
      invoice_id: invoiceId,
    };
    axios
      .post(process.env.REACT_APP_VOX_SEND_PERJAKBI as string, dataPost, {
        headers: {
          Authorization: `Bearer ${globalStateUser.token}`,
        },
      })
      .then((res) => {
        Swal.fire("Perjakbi Send!", "", "success");
        setIsLoading(false);
        // navigate('/vo-data')
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleSendDraftAgreement = (invoiceId: any) => {
    setIsLoading(true);
    const dataPost = {
      invoice_id: invoiceId,
    };
    axios
      .post(
        process.env.REACT_APP_VOX_SEND_DRAFT_AGREEMENT as string,
        dataPost,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.data) {
          Swal.fire({
            title: "Agreement is being generated!",
            timer: 20000,
          });
          Swal.showLoading();
          setIsLoading(false);
        } else {
          Swal.fire("Draft Agreement Send!", "", "success");
          setIsLoading(false);
        }
        // navigate('/vo-data')
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleSendOfficialAgreement = (invoiceId: any) => {
    setIsLoading(true);
    const dataPost = {
      invoice_id: invoiceId,
    };
    axios
      .post(
        process.env.REACT_APP_VOX_SEND_OFFICIAL_AGREEMENT as string,
        dataPost,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.data) {
          Swal.fire({
            title: "Agreement is being generated!",
            timer: 20000,
          });
          Swal.showLoading();
          setIsLoading(false);
        } else {
          Swal.fire("Agreement Send!", "", "success");
          setIsLoading(false);
        }
        // navigate('/vo-data')
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal w-full lg:w-7/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        {isLoading ? (
          <DetailModalLoading />
        ) : (
          <div>
            <label
              htmlFor="my-modal-3"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={toggleModal}
            >
              ✕
            </label>
            <div className="flex flex-col md:flex-row items-center">
              {invoiceDetail?.invoice_data_message !== "exists" ? (
                <div>
                  {invoiceDetail?.invoice_data_message === "draft" ? (
                    <div>
                      <button
                        className="btn btn-sm bg-slate-500 text-white border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                        onClick={toggleModalOptionDraftAgreement}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                        &nbsp;&nbsp;Draft Agreement File
                      </button>
                      {invoiceDetail?.invoice_activation_status ===
                      "activated" ? (
                        <button
                          className="btn btn-sm bg-green-400 border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                          onClick={toggleModalOptionPerjakbi}
                        >
                          <FontAwesomeIcon icon={faFileLines} />
                          &nbsp;&nbsp;Perjakbi
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-warning mr-0 md:mr-2"
                          onClick={() =>
                            toggleModalActivatePlan(invoiceDetail.invoice_no)
                          }
                        >
                          <FontAwesomeIcon icon={faFileCircleCheck} />
                          &nbsp;&nbsp;Active Plan
                        </button>
                      )}
                      {/* {location.pathname === "/vo-activation" ? null : ( */}
                      {/* <button
                        className="btn btn-sm bg-white border border-pink-700 text-pink-700 mr-0 mb-2 md:mr-2 md:mb-0 hover:bg-white hover:border-pink-700 hover:shadow-lg"
                        onClick={toggleModalOptionOfficialInvoice}
                      >
                        <FontAwesomeIcon icon={faFileArchive} />
                        &nbsp;&nbsp;Official Invoice
                      </button> */}
                      {/* )} */}
                      <button
                        className="btn btn-sm bg-yellow-600 text-black border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                        onClick={() =>
                          toggleModalGenerateAgreement(
                            invoiceDetail?.invoice_no
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                        &nbsp;&nbsp;Edit Agreement File
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="btn btn-sm bg-black text-white border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                        onClick={() =>
                          toggleModalGenerateAgreement(
                            invoiceDetail?.invoice_no
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faFile} />
                        &nbsp;&nbsp;Generate Agreement File
                      </button>
                      {/* {location.pathname === "/vo-activation" ? null : ( */}
                      {/* <button
                        className="btn btn-sm bg-white border border-pink-700 text-pink-700 mr-0 mb-2 md:mr-2 md:mb-0 hover:bg-white hover:border-pink-700 hover:shadow-lg"
                        onClick={toggleModalOptionOfficialInvoice}
                      >
                        <FontAwesomeIcon icon={faFileArchive} />
                        &nbsp;&nbsp;Official Invoice
                      </button> */}
                      {/* )} */}
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col md:flex-row items-center">
                  <div>
                    <button
                      className="btn btn-sm bg-pink-700 border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                      onClick={toggleModalOptionOfficialAgreement}
                    >
                      <FontAwesomeIcon icon={faFile} />
                      &nbsp;&nbsp;Agreement File
                    </button>
                    {invoiceDetail?.invoice_activation_status ===
                    "activated" ? (
                      <button
                        className="btn btn-sm bg-green-400 border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                        onClick={toggleModalOptionPerjakbi}
                      >
                        <FontAwesomeIcon icon={faFileLines} />
                        &nbsp;&nbsp;Perjakbi
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-warning mr-0 md:mr-2"
                        onClick={() =>
                          toggleModalActivatePlan(invoiceDetail.invoice_no)
                        }
                      >
                        <FontAwesomeIcon icon={faFileCircleCheck} />
                        &nbsp;&nbsp;Active Plan
                      </button>
                    )}
                    {/* <button className='btn btn-sm bg-green-400 border-0 mr-0 mb-2 md:mr-2 md:mb-0' onClick={() => toggleModalPerjakbi(invoiceDetail)}><FontAwesomeIcon icon={faFileLines} />&nbsp;&nbsp;View Perjakbi</button> */}
                    {/* {location.pathname === "/vo-activation" ? null : ( */}
                    {/* <button
                      className="btn btn-sm bg-white border border-pink-700 text-pink-700 mr-0 mb-2 md:mr-2 md:mb-0 hover:bg-white hover:border-pink-700 hover:shadow-lg"
                      onClick={toggleModalOptionOfficialInvoice}
                    >
                      <FontAwesomeIcon icon={faFileArchive} />
                      &nbsp;&nbsp;Official Invoice
                    </button> */}
                    {/* )} */}
                    <button
                      className="btn btn-sm bg-yellow-600 text-black border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                      onClick={() =>
                        toggleModalGenerateAgreement(invoiceDetail?.invoice_no)
                      }
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                      &nbsp;&nbsp;Edit Agreement File
                    </button>
                  </div>
                  {/* {
                                                invoiceDetail?.invoice_activation_status === "activated" ?
                                                    <>
                                                        <button className='btn btn-sm bg-green-400 border-0 mr-0 mb-2 md:mr-2 md:mb-0' onClick={() => toggleModalPerjakbi(invoiceDetail)}><FontAwesomeIcon icon={faFileLines} />&nbsp;&nbsp;View Perjakbi</button>
                                                        <button className='btn btn-sm bg-white border border-pink-700 text-pink-700 mr-0 mb-2 md:mr-2 md:mb-0 hover:bg-white hover:border-pink-700 hover:shadow-lg' onClick={() => toggleModalOfficialInvoice(invoiceDetail?.invoice_no)}><FontAwesomeIcon icon={faFileArchive} />&nbsp;&nbsp;Official Invoice</button>
                                                    </>
                                                    :
                                                    <div>
                                                        <button className='btn btn-sm bg-green-400 border-0 mr-0 mb-2 md:mr-2 md:mb-0' onClick={() => toggleModalPerjakbi(invoiceDetail)}><FontAwesomeIcon icon={faFileLines} />&nbsp;&nbsp;View Perjakbi</button>
                                                        <button className='btn btn-sm bg-white border ml-0 md:ml-2 border-pink-700 text-pink-700 mr-0 mb-2 md:mr-2 md:mb-0 hover:bg-white hover:border-pink-700 hover:shadow-lg' onClick={() => toggleModalOfficialInvoice(invoiceDetail?.invoice_no)}><FontAwesomeIcon icon={faFileArchive} />&nbsp;&nbsp;Official Invoice</button>
                                                    </div>
                                            } */}
                </div>
              )}
            </div>
            <p className="text-sm italic mt-4">
              <FontAwesomeIcon icon={faCircleExclamation} />
              &nbsp;Periksa secara berkala untuk melihat status Agreement yang
              sudah di Sign
            </p>
            <div className="divider"></div>
            <div>
              <h3 className="text-xl font-bold mb-2">Plan Info</h3>
              <div>
                {invoiceDetail?.details?.map((data: any, idx: any) => {
                  return (
                    <div
                      key={idx}
                      className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box mb-2"
                    >
                      <input type="checkbox" />
                      <div className="collapse-title text-xl font-medium">
                        {data?.product_name}
                      </div>
                      <div className="collapse-content">
                        <hr />
                        <div className="flex flex-row justify-evenly gap-3">
                          <div className="my-3">
                            <div className="my-3">
                              <p className="font-bold">Service Start Date</p>
                              <p className="font-light">
                                {data?.plan?.start_date}
                              </p>
                            </div>
                            <div className="my-3">
                              <p className="font-bold">Discount</p>
                              <p className="font-light">
                                {rupiah(data?.plan?.discount)}
                              </p>
                            </div>
                          </div>
                          <div className="my-3">
                            <div className="my-3">
                              <p className="font-bold">Renew / Expires Date</p>
                              <p className="font-light">
                                {data?.plan?.next_renew_date}
                              </p>
                            </div>
                            <div className="my-3">
                              <p className="font-bold">Product Price</p>
                              <p className="font-light">
                                {rupiah(data?.plan?.current_price)}
                              </p>
                            </div>
                          </div>
                          <div className="my-3">
                            <div className="my-3">
                              <p className="font-bold">Contact Term</p>
                              <p className="font-light">
                                {data?.plan?.contract_term}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={isOpenGenerateAgreement}
        onRequestClose={toggleModalGenerateAgreement}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        {isLoading ? (
          <div>
            <h3 className="text-lg font-bold text-center">Please Wait...</h3>
          </div>
        ) : (
          <div>
            <label
              htmlFor="my-modal-3"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={toggleModalGenerateAgreement}
            >
              ✕
            </label>
            <h3 className="text-xl font-bold">Generate Agreement</h3>
            <div className="divider"></div>
            <form onSubmit={handleSubmitGA(handleGenerateAgreement)}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">DID Number</span>
                </label>
                <input
                  type="number"
                  placeholder="DID number..."
                  className="input input-bordered w-full"
                  {...registerGA("did_no")}
                />
                <label className="label">
                  <span className="label-text">Location Bank Account</span>
                </label>
                <Controller
                  control={controlGA}
                  name="location_bank_account_id"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      value={optionsLocationBankAccount.find(
                        (c) => c.value === value
                      )}
                      name={name}
                      options={optionsLocationBankAccount}
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption.value);
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  )}
                />
                {errorsGA.location_bank_account_id ? (
                  <span className="text-sm text-red-700">Field required</span>
                ) : null}
                <label className="label">
                  <span className="label-text">Security Deposit</span>
                </label>
                <input
                  type="number"
                  placeholder="Security Deposit..."
                  className="input input-bordered w-full"
                  {...registerGA("security_deposit")}
                />
                {errorsGA.security_deposit ? (
                  <span className="text-sm text-red-700">Field required</span>
                ) : null}
                <label className="label">
                  <span className="label-text">Start Date</span>
                </label>
                <input
                  type="date"
                  className={`${
                    !errorsGA.start_date?.type
                      ? `input input-bordered text-black w-full`
                      : `input border-2 border-error text-black w-full`
                  }`}
                  {...registerGA("start_date", { required: true })}
                  // disabled={true}
                />
                <span className="text-error text-sm font-bold">
                  {errorsGA.start_date?.type === "required" && "Field required"}
                </span>
                <label className="label">
                  <span className="label-text">End Date</span>
                </label>
                <input
                  type="date"
                  className={`${
                    !errorsGA.end_date?.type
                      ? `input input-bordered text-black w-full`
                      : `input border-2 border-error text-black w-full`
                  }`}
                  {...registerGA("end_date", { required: true })}
                  // disabled={true}
                />
                <span className="text-error text-sm font-bold">
                  {errorsGA.end_date?.type === "required" && "Field required"}
                </span>
                <label className="label">
                  <span className="label-text">Extend Period</span>
                </label>
                <select
                  className={`${
                    !errorsGA.extend_period?.type
                      ? `select select-bordered`
                      : `select select-error`
                  }`}
                  {...registerGA("extend_period")}
                >
                  <option selected value="0">
                    Pick one
                  </option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Months</option>
                  <option value="3">3 Months</option>
                  <option value="4">4 Months</option>
                  <option value="5">5 Months</option>
                  <option value="6">6 Months</option>
                </select>
                <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                  <div className="w-full">
                    <label className="label">
                      <span className="label-text">
                        Additional Meeting Room Quota
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Additional Meeting Room Quota..."
                      defaultValue={0}
                      className="input input-bordered w-full"
                      {...registerGA("additional_meeting_room_quota")}
                    />
                    <label className="label">
                      <span className="label-text">
                        Additional Event Space Quota
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Additional Event Space Quota..."
                      defaultValue={0}
                      className="input input-bordered w-full"
                      {...registerGA("additional_event_space_quota")}
                    />
                  </div>
                  <div className="w-full">
                    <label className="label">
                      <span className="label-text">
                        Additional Workstation Quota
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Additional Workstation Quota..."
                      defaultValue={0}
                      className="input input-bordered w-full"
                      {...registerGA("additional_workstation_quota")}
                    />
                    <label className="label">
                      <span className="label-text">
                        Additional Private Office Quota
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Additional Private Office Quota..."
                      defaultValue={0}
                      className="input input-bordered w-full"
                      {...registerGA("additional_private_office_quota")}
                    />
                  </div>
                </div>
              </div>
              <div className="float-right mt-4">
                <button
                  className={
                    isLoading ? "btn loading" : "btn bg-pink-700 border-0"
                  }
                  type="submit"
                >
                  Generate
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={isOpenOptionOfficialInvoice}
        onRequestClose={toggleModalOptionOfficialInvoice}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalOptionOfficialInvoice}
          >
            ✕
          </label>
          <div>
            <h3 className="font-bold text-center">Official Invoice</h3>
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 mt-3">
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-white border border-pink-700 text-pink-700 mr-0 mb-2 md:mr-2 md:mb-0 hover:bg-white hover:border-pink-700 hover:shadow-lg"
                  }
                  onClick={() =>
                    handleSendOfficialInvoice(invoiceDetail?.invoice_no)
                  }
                >
                  {isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}
                  &nbsp;&nbsp;Send Official Invoice
                </button>
              </div>
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-white border border-pink-700 text-pink-700 mr-0 mb-2 md:mr-2 md:mb-0 hover:bg-white hover:border-pink-700 hover:shadow-lg"
                  }
                  onClick={() =>
                    toggleModalOfficialInvoice(invoiceDetail?.invoice_no)
                  }
                >
                  <FontAwesomeIcon icon={faFileArchive} />
                  &nbsp;&nbsp;View Official Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenOptionPerjakbi}
        onRequestClose={toggleModalOptionPerjakbi}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalOptionPerjakbi}
          >
            ✕
          </label>
          <div>
            <h3 className="font-bold text-center">Perjakbi</h3>
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 mt-3">
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-green-400 border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                  }
                  onClick={() => handleSendPerjakbi(invoiceDetail?.invoice_no)}
                >
                  {isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}
                  &nbsp;&nbsp;Send Perjakbi
                </button>
              </div>
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-green-400 border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                  }
                  onClick={() => toggleModalPerjakbi(invoiceDetail)}
                >
                  <FontAwesomeIcon icon={faFileLines} />
                  &nbsp;&nbsp;View Perjakbi
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenOptionDraftAgreement}
        onRequestClose={toggleModalOptionDraftAgreement}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalOptionDraftAgreement}
          >
            ✕
          </label>
          <div>
            <h3 className="font-bold text-center">Draft Agreement File</h3>
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 mt-3">
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-slate-500 text-white border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                  }
                  onClick={() =>
                    handleSendDraftAgreement(invoiceDetail?.invoice_no)
                  }
                >
                  {isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}
                  &nbsp;&nbsp;Send Draft Agreement File
                </button>
              </div>
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-slate-500 text-white border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                  }
                  onClick={() =>
                    toggleModalViewAgreement(invoiceDetail?.invoice_no)
                  }
                >
                  <FontAwesomeIcon icon={faFile} />
                  &nbsp;&nbsp;View Draft Agreement File
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenOptionOfficialAgreement}
        onRequestClose={toggleModalOptionOfficialAgreement}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-6/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalOptionOfficialAgreement}
          >
            ✕
          </label>
          <div>
            <h3 className="font-bold text-center">Agreement File</h3>
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 mt-3">
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-pink-700 border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                  }
                  onClick={() =>
                    handleSendOfficialAgreement(invoiceDetail?.invoice_no)
                  }
                >
                  {isLoading ? null : <FontAwesomeIcon icon={faPaperPlane} />}
                  &nbsp;&nbsp;Send Agreement File
                </button>
              </div>
              <div>
                <button
                  className={
                    isLoading
                      ? "btn btn-sm loading"
                      : "btn btn-sm bg-pink-700 border-0 mr-0 mb-2 md:mr-2 md:mb-0"
                  }
                  onClick={() =>
                    toggleModalViewAgreement(invoiceDetail?.invoice_no)
                  }
                >
                  <FontAwesomeIcon icon={faFile} />
                  &nbsp;&nbsp;View Agreement File
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenViewAgreement}
        onRequestClose={toggleModalViewAgreement}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-10/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalViewAgreement}
          >
            ✕
          </label>
          <iframe src={pdfAgreement}></iframe>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenPerjakbi}
        onRequestClose={toggleModalPerjakbi}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-10/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalPerjakbi}
          >
            ✕
          </label>
          <iframe src={pdfPerjakbi}></iframe>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModalActivatePlan}
        onRequestClose={toggleModalActivatePlan}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-7/12 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        {isLoading ? (
          <div>
            <h3 className="text-lg font-bold text-center">Please Wait...</h3>
          </div>
        ) : (
          <div>
            <label
              htmlFor="my-modal-3"
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={toggleModalActivatePlan}
            >
              ✕
            </label>
            <h3 className="text-xl font-bold">Activate Plan</h3>
            <div className="divider"></div>
            <div>
              <div className="form-control">
                <form onSubmit={handleSubmitAP(handleActivatePlan)}>
                  <div className="flex flex-col md:flex-row">
                    <div className="basis-1/2 mx-3">
                      <label className="label">
                        <span className="label-text">ID KTP</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        className={`${
                          !errorsAP.id_card_no?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...registerAP("id_card_no", { required: true })}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.id_card_no?.type === "required" &&
                          "Field required"}
                      </span>
                      {/* <label className="label">
                        <span className="label-text">Upload KTP</span>
                      </label>
                      <Controller
                        control={controlAP}
                        rules={{ required: true }}
                        name="id_card_file"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <>
                            <input
                              type="file"
                              id="ktp"
                              hidden
                              onChange={(e: any) => {
                                const files = e.target.files;
                                const file = files[0];
                                let reader: any = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                  setPlaceHolders({
                                    ...placeHolders,
                                    ktpName: e.target.files[0].name,
                                  });
                                  setValueAP(
                                    "id_card_file",
                                    reader.result.replace(
                                      /^data:image\/[a-z]+;base64,/,
                                      ""
                                    )
                                  );
                                };
                              }}
                            />
                            <label className="input-group w-full" htmlFor="ktp">
                              <input
                                type="text"
                                placeholder={
                                  placeHolders.ktpName
                                    ? `${placeHolders.ktpName}`
                                    : "no files..."
                                }
                                readOnly
                                className={`${
                                  !errorsAP.id_card_file?.type
                                    ? `input input-bordered text-black w-full`
                                    : `input border-2 border-error text-black w-full`
                                }`}
                              />
                              <span>Browse</span>
                            </label>
                          </>
                        )}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.id_card_file?.type === "required" &&
                          "Field required"}
                      </span> */}
                      <label className="label">
                        <span className="label-text">Alamat KTP</span>
                      </label>
                      <textarea
                        className={`${
                          !errorsAP.address_ktp?.type
                            ? `textarea textarea-bordered text-black w-full`
                            : `textarea textarea-error text-black w-full`
                        }`}
                        placeholder="Alamat KTP"
                        defaultValue={""}
                        {...registerAP("address_ktp", { required: true })}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.address_ktp?.type === "required" &&
                          "Field required"}
                      </span>
                      {/* <label className="label">
                      <span className="label-text">
                        Tanda Daftar Perusahaan
                      </span>
                    </label> */}
                      {/* <Controller
                      control={controlAP}
                      rules={{ required: true }}
                      name="company_reg_file"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <>
                          <input
                            type="file"
                            id="tdp"
                            hidden
                            onChange={(e: any) => {
                              const files = e.target.files;
                              const file = files[0];
                              let reader: any = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onload = () => {
                                setPlaceHolders({
                                  ...placeHolders,
                                  tdpName: e.target.files[0].name,
                                });
                                setValueAP(
                                  "company_reg_file",
                                  reader.result.replace(
                                    /^data:image\/[a-z]+;base64,/,
                                    ""
                                  )
                                );
                              };
                            }}
                          />
                          <label className="input-group w-full" htmlFor="tdp">
                            <input
                              type="text"
                              placeholder={
                                value
                                  ? `${placeHolders.tdpName}`
                                  : "no files..."
                              }
                              readOnly
                              className={`${
                                !errorsAP.company_reg_file?.type
                                  ? `input input-bordered text-black w-full`
                                  : `input border-2 border-error text-black w-full`
                              }`}
                            />
                            <span>Browse</span>
                          </label>
                        </>
                      )}
                    /> */}
                      {/* <span className="text-error text-sm font-bold">
                      {errorsAP.company_reg_file?.type === "required" &&
                        "Field required"}
                    </span> */}
                      <label className="label">
                        <span className="label-text">DID Number</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        className={`${
                          !errorsAP.did_no?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...registerAP("did_no", { required: true })}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.did_no?.type === "required" &&
                          "Field required"}
                      </span>
                      <label className="label">
                        <span className="label-text">Company Name</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        disabled={watchType === "2" ? true : false}
                        readOnly={watchType === "3" ? true : false}
                        className={`${
                          !errorsAP.company_name?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...registerAP("company_name")}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.company_name?.type === "required" &&
                          "Field required"}
                      </span>
                      {/* <label className="label">
                        <span className="label-text">Fax Number</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        className={`${
                          !errorsAP.fax_number?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...registerAP("fax_number", { required: true })}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.fax_number?.type === "required" &&
                          "Field required"}
                      </span> */}
                    </div>
                    <div className="basis-1/2 mx-3">
                      <label className="label">
                        <span className="label-text">NPWP Pribadi</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        className={`${
                          !errorsAP.tax_id_no?.type
                            ? `input input-bordered text-black w-full`
                            : `input border-2 border-error text-black w-full`
                        }`}
                        {...registerAP("tax_id_no", { required: true })}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.tax_id_no?.type === "required" &&
                          "Field required"}
                      </span>
                      {/* <label className="label">
                        <span className="label-text">Upload NPWP Pribadi</span>
                      </label>
                      <Controller
                        control={controlAP}
                        rules={{ required: true }}
                        name="tax_id_file"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <>
                            <input
                              type="file"
                              id="npwp_p"
                              hidden
                              onChange={(e: any) => {
                                const files = e.target.files;
                                const file = files[0];
                                let reader: any = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                  setPlaceHolders({
                                    ...placeHolders,
                                    npwpPribadiName: e.target.files[0].name,
                                  });
                                  setValueAP(
                                    "tax_id_file",
                                    reader.result.replace(
                                      /^data:image\/[a-z]+;base64,/,
                                      ""
                                    )
                                  );
                                };
                              }}
                            />
                            <label
                              className="input-group w-full"
                              htmlFor="npwp_p"
                            >
                              <input
                                type="text"
                                placeholder={
                                  placeHolders.npwpPribadiName
                                    ? `${placeHolders.npwpPribadiName}`
                                    : "no files..."
                                }
                                readOnly
                                className={`${
                                  !errorsAP.tax_id_file?.type
                                    ? `input input-bordered text-black w-full`
                                    : `input border-2 border-error text-black w-full`
                                }`}
                              />
                              <span>Browse</span>
                            </label>
                          </>
                        )}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.tax_id_file?.type === "required" &&
                          "Field required"}
                      </span> */}
                      <label className="label">
                        <span className="label-text">
                          Alamat NPWP Perusahaan
                        </span>
                      </label>
                      <textarea
                        className={`${
                          !errorsAP.address_npwp_company?.type
                            ? `textarea textarea-bordered text-black w-full`
                            : `textarea textarea-error text-black w-full`
                        }`}
                        placeholder="Alamat NPWP Perusahaan"
                        defaultValue={""}
                        {...registerAP("address_npwp_company", {
                          required: true,
                        })}
                      />
                      <span className="text-error text-sm font-bold">
                        {errorsAP.address_npwp_company?.type === "required" &&
                          "Field required"}
                      </span>
                      <Controller
                        control={controlAP}
                        rules={{ required: true }}
                        name="type"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <div>
                            <div className="flex flex-row flex-wrap">
                              <label className="label cursor-pointer w-max">
                                <input
                                  type="radio"
                                  value={1}
                                  className="radio checked:bg-red-500"
                                  onClick={() =>
                                    setValueAP(
                                      "company_name",
                                      dataForm.companyName
                                    )
                                  }
                                  {...registerAP("type")}
                                />
                                <span className="ml-2 label-text">
                                  Have company
                                </span>
                              </label>
                              <label className="label cursor-pointer w-max">
                                <input
                                  type="radio"
                                  value={2}
                                  className="radio checked:bg-red-500"
                                  onClick={() => setValueAP("company_name", "")}
                                  {...registerAP("type")}
                                />
                                <span className="ml-2 label-text">
                                  Company is being created
                                </span>
                              </label>
                              <label className="label cursor-pointer w-max">
                                <input
                                  type="radio"
                                  value={3}
                                  className="radio checked:bg-red-500"
                                  onClick={() =>
                                    setValueAP(
                                      "company_name",
                                      dataForm.clientName
                                    )
                                  }
                                  {...registerAP("type")}
                                />
                                <span className="ml-2 label-text">
                                  Use a personal name
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      />
                      {watchType === "1" ? (
                        <>
                          {/* <label className="label">
                            <span className="label-text">
                              Upload NPWP Perusahaan
                            </span>
                          </label>
                          <Controller
                            control={controlAP}
                            rules={{ required: true }}
                            name="company_tax_id_file"
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                            }) => (
                              <>
                                <input
                                  type="file"
                                  id="npwp_c"
                                  hidden
                                  onChange={(e: any) => {
                                    const files = e.target.files;
                                    const file = files[0];
                                    let reader: any = new FileReader();
                                    reader.readAsDataURL(file);
                                    reader.onload = () => {
                                      setPlaceHolders({
                                        ...placeHolders,
                                        npwpPerusahaanName:
                                          e.target.files[0].name,
                                      });
                                      setValueAP(
                                        "company_tax_id_file",
                                        reader.result.replace(
                                          /^data:image\/[a-z]+;base64,/,
                                          ""
                                        )
                                      );
                                    };
                                  }}
                                />
                                <label
                                  className="input-group w-full"
                                  htmlFor="npwp_c"
                                >
                                  <input
                                    type="text"
                                    placeholder={
                                      placeHolders.npwpPerusahaanName
                                        ? `${placeHolders.npwpPerusahaanName}`
                                        : "no files..."
                                    }
                                    readOnly
                                    className={`${
                                      !errorsAP.company_tax_id_file?.type
                                        ? `input input-bordered text-black w-full`
                                        : `input border-2 border-error text-black w-full`
                                    }`}
                                  />
                                  <span>Browse</span>
                                </label>
                              </>
                            )}
                          />
                          <span className="text-error text-sm font-bold">
                            {errorsAP.company_tax_id_file?.type ===
                              "required" && "Field required"}
                          </span> */}
                        </>
                      ) : null}
                      {watchType === "1" ? (
                        <>
                          <label className="label">
                            <span className="label-text">NPWP Perusahaan</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Type here"
                            className={`${
                              !errorsAP.company_tax_id_no?.type
                                ? `input input-bordered text-black w-full`
                                : `input border-2 border-error text-black w-full`
                            }`}
                            {...registerAP("company_tax_id_no", {
                              required: true,
                            })}
                          />
                          <span className="text-error text-sm font-bold">
                            {errorsAP.company_tax_id_no?.type === "required" &&
                              "Field required"}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={
                      isLoading
                        ? "btn loading w-full mt-3"
                        : "btn bg-pink-700 border-0 text-white w-full mt-3"
                    }
                  >
                    Activate
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={isOpenModalOfficialInvoice}
        onRequestClose={toggleModalOfficialInvoice}
        contentLabel="My dialog"
        className="mymodal w-11/12 lg:w-10/12 lg:h-6/6 overflow-y-auto max-h-full"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnEsc={false}
      >
        <div>
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={toggleModalOfficialInvoice}
          >
            ✕
          </label>
          <iframe src={pdfOfficialInvoice}></iframe>
        </div>
      </Modal>
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          {/* head */}
          <thead className="border border-slate-300">
            <tr>
              <th className="bg-gray-100 text-gray-600 text-center full-width">
                Invoice ID
              </th>
              <th className="bg-gray-100 text-gray-600 text-center">
                Date Paid
              </th>
              <th className="bg-gray-100 text-gray-600 text-center full-width">
                Client ID
              </th>
              <th className="bg-gray-100 text-gray-600 text-center full-width">
                Client Name
              </th>
              <th className="bg-gray-100 text-gray-600 text-center">Company</th>
              <th className="bg-gray-100 text-gray-600 text-center">Product</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((data: any, idx: any) => {
              return (
                <tr
                  key={idx}
                  className="hover cursor-pointer text-sm hover:bg-slate-100 border-x-2 border-b-2"
                  onClick={() => toggleModal(data)}
                >
                  <td className="text-center">{data.id}</td>
                  <td className="text-center full-width">{data.date_paid}</td>
                  <td className="text-center">{data.client_id}</td>
                  <td className="text-center">{data.client?.full_name}</td>
                  <td className="text-center">{data.client?.company_name}</td>
                  <td className="text-center">
                    {data.details?.map((data: any) => {
                      return data?.plan?.product?.product_name;
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
