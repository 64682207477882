import React from 'react'

export const ActivationTableLoading = () => {
    return (
        <div>
            <div className="overflow-x-auto">
                <table className='table-auto w-full'>
                    {/* head */}
                    <thead className="border border-slate-300">
                        <tr>
                            <th className='bg-gray-700 text-white text-center'>Invoice ID</th>
                            <th className='bg-gray-700 text-white text-center'>Date Paid</th>
                            <th className='bg-gray-700 text-white text-center'>Client ID</th>
                            <th className='bg-gray-700 text-white text-center'>Client Name</th>
                            <th className='bg-gray-700 text-white text-center'>Company</th>
                            <th className='bg-gray-700 text-white text-center'>Product</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(10)].map((x, i) =>
                            <tr className='border-x-2 border-b-2' key={i}>
                                <td>
                                    <div className="animate-pulse flex space-x-4 w-9 mx-auto">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-2 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="animate-pulse flex space-x-4 w-16 mx-auto">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-2 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="animate-pulse flex space-x-4 w-32 mx-auto">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-2 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="animate-pulse flex space-x-4 w-32 mx-auto">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-2 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="animate-pulse flex space-x-4 w-32 mx-auto">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-2 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="animate-pulse flex space-x-4 w-16 mx-auto">
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="space-y-3">
                                                <div className="h-2 bg-slate-700 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
