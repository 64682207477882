import React from 'react'

export const DealInProgressTabsLoading = () => {
    return (
        <div className="overflow-x-auto border border-slate-200 rounded-md mb-3">
            <table className="table w-full">
                {/* head */}
                <thead>
                    <tr>
                        <th />
                        <th>Deals ID</th>
                        <th>Invoice Number</th>
                        <th>Status</th>
                        <th>Sales In Charge</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th />
                        <td>
                            <div className="animate-pulse flex space-x-4 w-9">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-9">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-9">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-9">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th />
                        <td>
                            <div className="animate-pulse flex space-x-4 w-9">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-9">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-9">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="animate-pulse flex space-x-4 w-9">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="space-y-3">
                                        <div className="h-2 bg-slate-700 rounded" />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
