import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { PlanDetailTabsLoading } from "./PlanDetailTabsLoading/PlanDetailTabsLoading";

type propsType = {
  data?: any;
};

export const PlanDetailTabs = ({ data }: propsType) => {
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        (process.env.REACT_APP_VOX_TABS_DETAIL as string) + `/${data}/plans`,
        {
          headers: {
            Authorization: `Bearer ${globalStateUser.token}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setDataTable(res.data.data);
      })
      .catch((error) => {
        setIsLoading(true);
        console.log(error);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <PlanDetailTabsLoading />
      ) : (
        <div className="overflow-x-auto border border-slate-200 rounded-md">
          <table className="table w-full">
            {/* head */}
            <thead>
              <tr>
                <th />
                <th>Plan ID</th>
                <th>Plan Name</th>
                <th>Location</th>
                <th>Status Plan</th>
                <th>Invoice Id</th>
                <th>Status Invoice</th>
              </tr>
            </thead>
            <tbody>
              {dataTable.map((data: any, idx: any) => {
                return (
                  <tr key={idx}>
                    <th />
                    <td>{data?.id}</td>
                    <td>{data?.product?.product_name}</td>
                    <td>{data?.product?.location?.name}</td>
                    <td>{data?.status_plan}</td>
                    <td>{data?.invoice_dt[0]?.invoice_id}</td>
                    <td>{data?.invoice_dt[0]?.invoice?.status_invoice}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
