import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setClientId, setClientName } from "../../../store/userSlice";

type propsType = {
  data?: any[];
};

export const ClientTable = ({ data }: propsType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToDetail = (data?: any) => {
    dispatch(setClientId(data.client_id));
    dispatch(setClientName(data.full_name));
    if (location.pathname === "/clients") {
      navigate("/client-detail");
    } else if (location.pathname === "/so-data") {
      navigate("/so-detail");
    } else if (location.pathname === "/vo-data") {
      navigate("/vo-detail");
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        {/* head */}
        <thead className="border border-slate-300">
          <tr>
            <th className="bg-gray-100 text-gray-600">Client Id</th>
            <th className="bg-gray-100 text-gray-600 max-w-md text-left">
              Name
            </th>
            <th className="bg-gray-100 text-gray-600 text-left">Email</th>
            <th className="bg-gray-100 text-gray-600 text-center full-width">
              Company Name
            </th>
            <th className="bg-gray-100 text-gray-600 ">Location</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((data, idx) => {
            return (
              <tr
                className="cursor-pointer text-sm hover:bg-slate-100 border-x-2 border-b-2"
                key={idx}
                onClick={() => goToDetail(data)}
              >
                <td className="text-center">{data.client_id}</td>
                <td className="text-left">{data.full_name}</td>
                <td className="text-left">{data.company_name}</td>
                <td className="text-center">{data.company_name}</td>
                <td className="text-center">{data.location?.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
