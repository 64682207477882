import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRotateRight,
  faChevronDown,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import logo from "../../assets/icon.png";
import { RootState } from "../../store/store";
import { logout } from "../../store/userSlice";

export const ResponsiveDrawer = () => {
  const globalStore = useSelector((state: RootState) => {
    return state.userData;
  });
  const dispatch = useDispatch();

  return (
    <>
      <div className="drawer drawer-mobile">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col">
          {/* Navbar */}
          <div className="navbar bg-base-100 shadow-xl">
            <div className="flex-none lg:hidden">
              <label className="btn btn-square btn-ghost" htmlFor="my-drawer-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-5 h-5 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </label>
            </div>
            <div className="flex-1">
              <a className="btn btn-ghost normal-case text-xl"></a>
            </div>
            <div className="flex-none">
              <div className="dropdown dropdown-end">
                {/* <label tabIndex={0} className="btn btn-ghost btn-circle">
                                    <div className="indicator">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
                                        <span className="badge badge-xs badge-primary indicator-item" />
                                    </div>
                                </label>
                                <div tabIndex={0} className="mt-3 card card-compact dropdown-content w-52 bg-base-100 shadow">
                                    <div className="card-body">
                                        <span className="font-bold text-lg">8 Items</span>
                                        <span className="text-info">Subtotal: $999</span>
                                        <div className="card-actions">
                                            <button className="btn btn-primary btn-block">View cart</button>
                                        </div>
                                    </div>
                                </div> */}
              </div>
              <div className="dropdown dropdown-end">
                <label
                  tabIndex={0}
                  className="btn btn-ghost btn-circle avatar online placeholder"
                >
                  <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                    <span>
                      {`${globalStore.name}`
                        .split(" ")
                        .map((n) => n[0])
                        .join("")}
                    </span>
                  </div>
                </label>
                <ul
                  tabIndex={0}
                  className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 w-52"
                >
                  <p className="ml-4 mb-2 font-semibold">
                    Hi, {globalStore.name}!
                  </p>
                  {/* <li>
                                        <a className="justify-between">
                                            Profile
                                            <span className="badge">New</span>
                                        </a>
                                    </li> */}
                  {/* <li><a>Settings</a></li> */}
                  <li>
                    <a
                      onClick={() => {
                        dispatch(logout());
                      }}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Navbar */}
          {/* Page content here */}
          <div className="m-8">
            <Outlet />
          </div>
          {/* <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden">Open drawer</label> */}
        </div>
        <div className="drawer-side shadow-xl border border-r-slate-300 border-b-white">
          <label htmlFor="my-drawer-2" className="drawer-overlay" />
          <ul
            className="menu overflow-y-auto w-64 bg-base-100 text-base-content"
            style={{ zIndex: 0 }}
          >
            {/* Sidebar content here */}
            <li className="hover:bg-white active:bg-white mb-3">
              <div className="flex flex-row">
                <div>
                  <img src={logo} alt="" className="w-10 max-w-fit" />
                </div>
                <div className="inline-block align-middle">
                  <p className="font-bold text-xl text-slate-400">vox-apx</p>
                </div>
              </div>
            </li>
            <li>
              <Link to="/clients">
                <p>
                  <FontAwesomeIcon icon={faUser} size="lg" />
                  &nbsp;&nbsp;List Client
                </p>
              </Link>
            </li>
            <div>
              {/* <li><Link to="/dashboard"><p className='ml-4'>Dashboard</p></Link></li> */}
              <div className="collapse collapse-arrow">
                <input type="checkbox" />
                <div className="collapse-title text-base">
                  <div>
                    <FontAwesomeIcon icon={faFileInvoice} size="lg" />
                    &nbsp;&nbsp;Renewal
                  </div>
                </div>
                <div className="collapse-content">
                  <div className="collapse collapse-arrow w-60">
                    <input type="checkbox" />
                    <div className="collapse-title text-base font-normal ml-8">
                      <div>Virtual Office</div>
                    </div>
                    <div className="collapse-content">
                      <li>
                        <Link to="/vo-data">
                          <p className="ml-16">Client Renewal</p>
                        </Link>
                      </li>
                      {/* <li><Link to="/vo-client"><p className='ml-16'>VO Client</p></Link></li> */}
                      {/* <li><Link to="/vo-cancel"><p className='ml-16'>Cancel</p></Link></li> */}
                      <li>
                        <Link to="/vo-activation">
                          <p className="ml-16">Activation</p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/vo-cancelation">
                          <p className="ml-16">Cancelation</p>
                        </Link>
                      </li>
                    </div>
                  </div>
                  <div className="collapse collapse-arrow w-60">
                    <input type="checkbox" />
                    <div className="collapse-title text-base font-normal ml-8">
                      <div>Service Office</div>
                    </div>
                    <div className="collapse-content">
                      <li>
                        <Link to="/so-client-collection">
                          <p className="ml-16">Client Collection</p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/so-location">
                          <p className="ml-16">Create Room Location</p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/so-manage-location">
                          <p className="ml-16">View Location</p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/so-serviced-office">
                          <p className="ml-16">Create Product</p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/so-data">
                          <p className="ml-16">Client Renewal</p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/so-activation">
                          <p className="ml-16">Activation</p>
                        </Link>
                      </li>
                      <li>
                        <Link to="/so-cancelation">
                          <p className="ml-16">Cancelation</p>
                        </Link>
                      </li>
                      {/* <li><Link to="/so-createnewproduct"><p className='ml-16'>Create New Product</p></Link></li> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
        {/* <div className='bottom-3 left-4'>
                    <p className='text-sm bg-white'>Copyright © 2022 PT VOffice</p>
                </div> */}
      </div>
    </>
  );
};
