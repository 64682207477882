import { faEye, faEyeSlash, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/icon.png';
import Swal from 'sweetalert2'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setName, storeToken } from '../../store/userSlice';

type LoginData = {
    email: string
    password: string
}

export const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<LoginData>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isPassShown, setIsPassShown] = useState(false);
    const globalStateUser = useSelector((state: RootState) => {
        return state.userData
    });

    useEffect(() => {
        if (globalStateUser.token) {
            navigate('/vo-data');
        }
    }, []);

    const changeShownPass = () => {
        setIsPassShown(!isPassShown)
    }

    const onSubmit = handleSubmit(data => {
        axios.post(
            (process.env.REACT_APP_AUTH_LOGIN as string),
            data
        )
            .then(res => {
                navigate('/vo-data')
                dispatch(storeToken(res.data.data.token))
                dispatch(setName(res.data.data.user.full_name))
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${error.response.data.message}`,
                })
            })
    })

    return (
        <>
            <div className="hero min-h-screen" style={{ backgroundImage: 'url(https://vox.hardcore.co.id/atmos/getting%20started/light/assets/img/login.svg)' }}>
                <div className="hero-overlay bg-opacity-60" />
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-md">
                        <div className='card flex-shrink-0 w-full sm:w-96 max-w-sm shadow-2xl bg-base-100'>
                            <div className="card-body">
                                <div className='flex flex-col text-center'>
                                    <div className='flex justify-center'>
                                        <img src={logo} alt="" className='w-14' />
                                    </div>
                                    <div>
                                        <p className='font-bold text-lg text-black'>vox-apx</p>
                                    </div>
                                </div>
                                <form onSubmit={onSubmit}>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Email</span>
                                        </label>
                                        <input type="text" placeholder="email" className={`${!errors.email?.type ? `input input-bordered text-black` : `input border-2 border-error text-black`}`} {...register('email', { required: true })} />
                                        <div className="label justify-start">
                                            {errors.email ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                            <span className='text-error text-sm font-bold'>
                                                {errors.email?.type === "required" && "Email required"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Password</span>
                                        </label>
                                        <div className='flex flex-nowrap items-center'>
                                            <input type={isPassShown === true ? "text" : "password"} placeholder="password" className={`${!errors.password?.type ? `input input-bordered text-black w-full` : `input border-2 border-error text-black w-full`}`} {...register('password', { required: true })} />
                                            {
                                                isPassShown === true ?
                                                    <FontAwesomeIcon icon={faEye} className="-ml-10 text-black" onClick={changeShownPass} />
                                                    :
                                                    <FontAwesomeIcon icon={faEyeSlash} className="-ml-10 text-black" onClick={changeShownPass} />
                                            }
                                        </div>
                                        <div className="label justify-start">
                                            {errors.password ? <FontAwesomeIcon icon={faTimesCircle} className='text-error mr-2' /> : ""}
                                            <span className='text-error text-sm font-bold'>
                                                {errors.password?.type === "required" && "Password required"}
                                            </span>
                                        </div>
                                        {/* <label className="label">
                                            <a href="#" className="label-text-alt link link-hover">Forgot password?</a>
                                        </label> */}
                                    </div>
                                    <div className="form-control mt-6">
                                        <button className="btn btn-primary" type='submit'>Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
