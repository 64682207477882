import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'userData',
    initialState: {
        token: null,
        name: null,
        //for invoice        
        clientId: null,
        clientName: null,
        locationId: null,
        locationName: null,
        product1: null,
        product1Name: null,
        product2: null,
        product2Name: null,
        vat: null,
        //end invoice
    },
    reducers: {
        storeToken: (state, action) => {
            state.token = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setClientId: (state, action) => {
            state.clientId = action.payload
        },
        setClientName: (state, action) => {
            state.clientName = action.payload
        },
        setLocationId: (state, action) => {
            state.locationId = action.payload
        },
        setLocationName: (state, action) => {
            state.locationName = action.payload
        },
        setProduct1: (state, action) => {
            state.product1 = action.payload
        },
        setProduct1Name: (state, action) => {
            state.product1Name = action.payload
        },
        setProduct2: (state, action) => {
            state.product2 = action.payload
        },
        setProduct2Name: (state, action) => {
            state.product2Name = action.payload
        },
        setVat: (state, action) => {
            state.vat = action.payload
        },
        logout: (state) => {
            state.token = null
            state.name = null
            state.clientId = null
            state.clientName = null
            state.locationId = null
            state.locationName = null
            state.product1 = null
            state.product1Name = null
            state.product2 = null
            state.product2Name = null
            state.vat = null
        },
    }
});

export const userReducer = userSlice.reducer;

export const { logout, setName, setClientId, storeToken, setLocationId, setProduct1, setProduct2, setVat, setClientName, setLocationName, setProduct1Name, setProduct2Name } = userSlice.actions;
