import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ResponsiveDrawer } from './components/ResponsiveDrawer/ResponsiveDrawer';
import { Login } from './page/Login/Login';
import { Route, Routes } from 'react-router-dom';
import { VoData } from './page/Renewal/Vo/VoData/VoData';
import { Dashboard } from './page/Dashboard/Dashboard';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { NotFound } from './page/NotFound/NotFound';
import { VoClient } from './page/Renewal/Vo/VoClient/VoClient';
import { SoClient } from './page/Renewal/So/SoClient/SoClient';
import { SoData } from './page/Renewal/So/SoData/SoData';
import { DetailVoData } from './page/Renewal/Vo/DetailVoData/DetailVoData';
import { CreateNewProduct } from './page/Renewal/So/CreateNewProduct/CreateNewProduct';
import { VoCancelation } from './page/Renewal/Vo/VoCancelation/VoCancelation';
import { VoActivation } from './page/Renewal/Vo/VoActivation/VoActivation';
import { ClientCollection } from './page/Renewal/So/ClientCollection/ClientCollection';
import { SoActivation } from './page/Renewal/So/SoActivation/SoActivation';
import { SoCancelation } from './page/Renewal/So/SoCancelation/SoCancelation';
import { CreateServicedOffice } from './page/CreateServicedOffice/CreateServicedOffice';
import { CreateProductServicedOffice } from './page/CreateProductServicedOffice/CreateProductServicedOffice';
import { Clients } from './page/Clients/Clients';
import { ManageServicedOfficeLocation } from './page/ManageServicedOfficeLocation/ManageServicedOfficeLocation';
import { SignaturePage } from './page/SignaturePage/SignaturePage';

function App() {
  return (
    <>
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Login />} />
        <Route path='/signature/:invoiceId' element={<SignaturePage />} />
        <Route element={<PrivateRoute />}>
          <Route element={<ResponsiveDrawer />} >
            {/* <Route path='/dashboard' element={<Dashboard />} /> */}
            {/* <Route path='/so-client' element={<SoClient />} /> */}
            <Route path='/clients' element={<Clients />} />
            <Route path='/client-detail' element={<DetailVoData />} />
            <Route path='/so-client-collection' element={<ClientCollection />} />
            <Route path='/so-location' element={<CreateServicedOffice />} />
            <Route path='/so-manage-location' element={<ManageServicedOfficeLocation />} />
            <Route path='/so-serviced-office' element={<CreateProductServicedOffice />} />
            <Route path='/so-data' element={<SoData />} />
            <Route path='/so-detail' element={<DetailVoData />} />
            {/* <Route path='/so-createnewproduct' element={<CreateNewProduct />} /> */}
            <Route path='/so-activation' element={<SoActivation />} />
            <Route path='/so-cancelation' element={<SoCancelation />} />
            {/* <Route path='/vo-client' element={<VoClient />} /> */}
            <Route path='/vo-data' element={<VoData />} />
            <Route path='/vo-detail' element={<DetailVoData />} />
            <Route path='/vo-activation' element={<VoActivation />} />
            <Route path='/vo-cancelation' element={<VoCancelation />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
